import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import {
  getField,
  getActivated,
  makeGetTextFor,
} from '../../selectors';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { cldbSearchRequest } from '../../api';

class CldbTableSelector extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    activated: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  // defaultOptions lets us cache the returned value from the async call so that we can continue presenting
  // it to the user even if they click away, or clear, their input.
  constructor(props) {
    super(props);

    this.state = {
      defaultOptions: null,
    };
  }

  handleChange = (e) => {
    // This is needed if the user intentionally clears the value.
    this.props.onChange(e === null ? null : e.value);
  }

  labelForRow = (row) => {
    const { field } = this.props;
    const tableOptions = field.get('tableOptions');
    const keyColumnAttributeName = tableOptions.get('key_column_attribute_name');
    const workbaseSelectorDisplayColumnId = field.get('workbaseSelectorDisplayColumnId');
    const displayValueAttributeName = `column_${workbaseSelectorDisplayColumnId}_value`;
    const denormalizedPlaintextDisplayValueAttributeName = `column_${workbaseSelectorDisplayColumnId}_value_denormalized_plaintext`;
    let label = row[displayValueAttributeName] || row[keyColumnAttributeName];
    if (typeof label === 'object' && label !== null) {
      // this is richtext or a file - use the denormalized plaintext value instead
      label = row[denormalizedPlaintextDisplayValueAttributeName];
    }
    return label;
  }

  getCldbData = (inputValue, callback) => {
    const { field } = this.props;
    const tableOptions = field.get('tableOptions');
    const matchingKeysEndpoint = tableOptions.get('matching_keys_endpoint_base');
    const tableId = tableOptions.get('cldb_table_id');
    const accessCode = tableOptions.get('access_code');
    const workbaseSelectorDisplayColumnId = field.get('workbaseSelectorDisplayColumnId');

    const success = (searchResults) => {
      const { matchingRows } = searchResults;

      const defaultOptions = matchingRows.map((row) => {
        const label = this.labelForRow(row);
        return { label, value: row };
      });

      this.setState({ defaultOptions });

      return callback(defaultOptions);
    };

    const failure = (message) => {
      if (this.props.activated) {
        alert(this.props.getTextFor('validations.there-was-an-error-refresh'));
      } else {
        alert(`${this.props.getTextFor('validations.there-was-an-error')}: ${message}`);
      }

      return callback([]);
    };

    return cldbSearchRequest(matchingKeysEndpoint, tableId, accessCode, inputValue, workbaseSelectorDisplayColumnId, success, failure);
  }

  getSelectedValue = () => {
    const { value } = this.props;

    if (!value || value === '') return null;

    const label = this.labelForRow(value.toJS());
    return { label, value };
  }

  renderInput = () => {
    // See https://github.com/JedWatson/react-select/issues/614 for info on debouncing/throttling react-select async data
    const throttledGetCldbData = throttle((inputValue, callback) => this.getCldbData(inputValue, callback), 500);

    return (
      <React.Fragment>
        <ReactSelectWrapper
          selectedValue={this.getSelectedValue()}
          onChange={this.handleChange}
          asyncOptions={throttledGetCldbData}
          defaultOptions={this.state.defaultOptions}
          disabled={this.props.prerenderMode}
          async
        />
      </React.Fragment>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <span className="page-block__inline-wrapper">
          {this.props.renderRequired()}
          {this.renderInput()}
          {this.props.renderWarnings()}
        </span>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  const activated = getActivated(state);

  return {
    field,
    activated,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(CldbTableSelector);
