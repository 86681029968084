import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
  makeGetTextFor,
} from '../../selectors';
import Submit from './Submit';
// import MarkdownRenderer from '../MarkdownRenderer';

class CompletedPayment extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  renderSubquestion = () => {
    return (
      <div className="page-block__markdownRenderer">
        {this.props.getTextFor('payments.completed')}
      </div>
    );
  }

  renderSubmit = () => {
    return (
      <Submit />
    );
  }

  render() {
    return (
      <div className="page-block page-block__question animate__animated">
        {this.renderSubquestion()}
        {this.renderSubmit()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);

  return {
    block,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(CompletedPayment);
