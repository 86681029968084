import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import { makeGetTextFor } from '../selectors';

class ReactSelectWrapper extends React.Component {
  static propTypes = {
    options: PropTypes.array,
    asyncOptions: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.object,
    defaultOptions: PropTypes.array,
    creatable: PropTypes.bool,
    async: PropTypes.bool,
    getTextFor: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    options: [],
    asyncOptions: () => {},
    selectedValue: null,
    defaultOptions: null,
    creatable: false,
    async: false,
    placeholder: null,
    isClearable: true,
    disabled: false,
  }

  render() {
    const placeholder = this.props.placeholder || this.props.getTextFor('select-one');

    if (this.props.creatable) {
      return (
        <CreatableSelect
          options={this.props.options}
          onChange={this.props.onChange}
          value={this.props.selectedValue}
          placeholder={placeholder}
          className="select-input"
          classNamePrefix="select-input"
          isSearchable
          isClearable={this.props.isClearable}
          isDisabled={this.props.disabled}
        />
      );
    }

    if (this.props.async) {
      return (
        <AsyncSelect
          loadOptions={this.props.asyncOptions}
          onChange={this.props.onChange}
          value={this.props.selectedValue}
          placeholder={this.props.getTextFor('async-placeholder')}
          defaultOptions={this.props.defaultOptions}
          className="select-input"
          classNamePrefix="select-input"
          isSearchable
          isClearable={this.props.isClearable}
          isDisabled={this.props.disabled}
        />
      );
    }

    return (
      <Select
        options={this.props.options}
        onChange={this.props.onChange}
        value={this.props.selectedValue}
        placeholder={placeholder}
        className="select-input"
        classNamePrefix="select-input"
        isSearchable
        isClearable={this.props.isClearable}
        isDisabled={this.props.disabled}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(ReactSelectWrapper);
