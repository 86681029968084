import { evaluateExpressions } from '../evaluation/expressions';
import { emptyMap } from '../utils/emptiness';

export const getSession = (state) => {
  return (state.get('session') || emptyMap).get(getSessionId(state));
};

export const getSessionId = (state) => {
  return state.get('sessionId');
};

export const getSessionMetadata = (state) => {
  return getSession(state).get('metadata');
};

export const getSessionKey = (state) => {
  return getSessionMetadata(state).get('session_key');
};

export const getBlockId = (state) => {
  return state.get('blockId');
};

export const getBlock = (state) => {
  return (state.get('block') || emptyMap).get(getBlockId(state) || '0');
};

export const getFields = (state) => {
  return state.get('fields') || emptyMap;
};

export const getFieldsForBlock = (state, blockId) => {
  const block = getBlock(state, blockId);
  return block.get('fields').map((fieldId) => {
    return getField(state, fieldId);
  });
};

export const getField = (state, fieldId) => {
  return getFields(state).get(fieldId) || emptyMap;
};

export const getFieldIdForVariableName = (state, variableName) => {
  return state.get('fieldIdsByVariableName').get(variableName);
};

export const getOnscreen = (state) => {
  return state.get('onscreen') || emptyMap;
};

export const getWarnings = (state) => {
  return state.get('warnings') || emptyMap;
};

export const makeOnscreenValueForVariable = (state) => {
  return (variableName) => {
    return getOnscreenValueForVariable(state, variableName);
  };
};

export const getOnscreenValueForVariable = (state, variableName) => {
  return getOnscreen(state).get(variableName);
};

export const getChoices = (state) => {
  return state.get('choices') || emptyMap;
};

export const getChoice = (state, choiceId) => {
  return getChoices(state).get(choiceId) || emptyMap;
};

export const getChoicesForField = (state, fieldId) => {
  return getField(state, fieldId).get('choices').map((choiceId) => {
    return getChoice(state, choiceId);
  });
};

export const shouldDisplayField = (state, fieldId) => {
  const field = getField(state, fieldId);
  const showIf = field.get('showIf');
  const inline = field.get('inline');

  if (!showIf || inline) {
    return true;
  }

  const evaluation = evaluateExpressions(state, field.get('showIfExpressions'));
  return Boolean(evaluation);
};

export const makeShouldDisplayField = (state) => {
  return (fieldId) => {
    return shouldDisplayField(state, fieldId);
  };
};

export const getVariables = (state) => {
  return state.get('variables') || emptyMap;
};

export const getVariableValue = (state, variableName) => {
  const variables = getVariables(state).get('values') || emptyMap;
  return variables.get(variableName) || emptyMap;
};

export const getKnownValues = (state) => {
  return getVariables(state).get('values') || emptyMap;
};

export const getDefinitions = (state) => {
  return getVariables(state).get('definitions') || emptyMap;
};

export const knownValuePresentFor = (state, reference) => {
  return getKnownValues(state).has(reference);
};

export const knownValueFor = (state, reference) => {
  return getKnownValues(state).get(reference);
};

export const onscreenValuePresentFor = (state, reference) => {
  const fieldId = getFieldIdForVariableName(state, reference);
  if (fieldId) {
    if (shouldDisplayField(state, fieldId)) {
      return getOnscreen(state).has(reference);
    }
  }
  return false;
};

export const definitionPresentFor = (state, reference) => {
  return getDefinitions(state).has(reference);
};

export const definitionFor = (state, reference) => {
  return getDefinitions(state).get(reference);
};

export const getSubmissibleOnscreenVariables = (state) => {
  return getOnscreen(state).filter((value, key) => {
    return onscreenValuePresentFor(state, key);
  });
};

export const getStyles = (state) => {
  return state.get('styles');
};

export const getHideNavbar = (state) => {
  return getStyles(state).get('hide_navbar');
};

export const getAppName = (state) => {
  return getStyles(state).get('app_name');
};

export const getAppLogo = (state) => {
  return getStyles(state).get('app_logo');
};

export const getExitButtonUrl = (state) => {
  return getStyles(state).get('exit_button_url');
};

export const getLoopVariableInProgress = (state) => {
  return state.get('loopVariableInProgress');
};

export const getActivated = (state) => {
  return state.get('activated');
};

export const getSandbox = (state) => {
  return getSession(state).get('sandbox');
};

export const getPrerenderMode = (state) => {
  return getSession(state).get('prerender_mode');
};

export const getDeveloperMode = (state) => {
  return getSession(state).get('developer_mode');
};

export const getNullDisposition = (state) => {
  return getSession(state).get('null_disposition');
};

export const getSaveAndContinueEnabled = (state) => {
  return state.get('saveAndContinueEnabled');
};

export const getClientPortalEndpoint = (state) => {
  return state.get('clientPortalEndpoint');
};

export const getLoopValue = (state) => {
  const loopVariableInProgress = getLoopVariableInProgress(state);
  return knownValueFor(state, loopVariableInProgress);
};

export const getBackNeedsConfirmation = (state) => {
  const block = getBlock(state);
  return block.get('type') === 'loop_review';
};

export const getBackConfirmationText = (state) => {
  const block = getBlock(state);
  if (block.get('type') === 'loop_review') {
    return getTextFor(state, 'loops.back-warning');
  }
  return '';
};

export const getMarkdownAttachments = (state) => {
  return state.get('markdownAttachments') || emptyMap;
};

export const getWordAttachments = (state) => {
  return state.get('wordAttachments') || emptyMap;
};

export const getPdfAttachments = (state) => {
  return state.get('pdfAttachments') || emptyMap;
};

export const getFileAttachments = (state) => {
  return state.get('fileAttachments') || emptyMap;
};

export const getMarkdownAttachment = (state, attachmentId) => {
  return getMarkdownAttachments(state).get(attachmentId) || emptyMap;
};

export const getWordAttachment = (state, attachmentId) => {
  return getWordAttachments(state).get(attachmentId) || emptyMap;
};

export const getPdfAttachment = (state, attachmentId) => {
  return getPdfAttachments(state).get(attachmentId) || emptyMap;
};

export const getFileAttachment = (state, attachmentId) => {
  return getFileAttachments(state).get(attachmentId) || emptyMap;
};

const getAssembledDocuments = (state) => {
  return state.get('assembledDocuments') || emptyMap;
};

export const getAssembledDocumentsForTemplate = (state, globalTemplateId) => {
  return getAssembledDocuments(state).get(globalTemplateId) || emptyMap;
};

export const getUi = (state) => {
  return state.get('ui') || emptyMap;
};

export const getIsWorking = (state) => {
  return getUi(state).get('working');
};

export const getHasSessionWebsocketConnection = (state) => {
  return getUi(state).get('hasSessionWebsocketConnection');
};

export const getHasAssemblyWebsocketConnection = (state) => {
  return getUi(state).get('hasAssemblyWebsocketConnection');
};

export const getFastForwardProgress = (state) => {
  return getUi(state).get('fastForwardProgress');
};

export const getSnapshotStatusMessage = (state) => {
  return getUi(state).get('snapshotStatusMessage');
};

export const getIsMakingSnapshot = (state) => {
  return getUi(state).get('isMakingSnapshot');
};

export const getSaveAndContinueModalShowing = (state) => {
  return getUi(state).get('saveAndContinueModalShowing');
};

const getPresignedS3Urls = (state) => {
  return state.get('presignedS3Urls') || emptyMap;
};

export const getPresignedS3UrlForVariable = (state, variableName) => {
  return getPresignedS3Urls(state).get(variableName);
};

export const getSelectedCurrency = (state) => {
  return state.get('selectedCurrency');
};

export const getSelectedLanguage = (state) => {
  return state.get('selectedLanguage') || 'en';
};

export const getTranslations = (state) => {
  return state.get('translations');
};

export const getTextFor = (state, entry, variables = {}) => {
  const language = getSelectedLanguage(state);
  let translation = (getTranslations(state).get(language) || emptyMap).getIn(entry.split('.'));

  if (!translation) {
    if (window.Rollbar) {
      window.Rollbar.error(`Translation not found for '${entry}' in language '${language}'`);
    } else {
      console.log(`Translation not found for '${entry}' in language '${language}'`);
    }
  }

  Object.entries(variables).forEach(([key, value]) => {
    translation = translation.replace(`%{${key}}`, value);
  });

  return translation;
};

export const makeGetTextFor = (state) => {
  return (entry, variables = {}) => {
    return getTextFor(state, entry, variables);
  };
};

export const getFirstAssembledDocument = (state) => {
  const assembledDocuments = getAssembledDocuments(state);
  return assembledDocuments.toList().get(0) || (emptyMap);
};

export const getHellosignClientId = (state) => {
  const session = getSession(state);
  return session.get('hellosign_client_id');
};

export const getIncludeClAttribution = (state) => {
  return state.get('includeClAttribution');
};

export const getFastForwarding = (state) => {
  return getSession(state).get('fast_forwarding');
};

export const getCurrentWaypoint = (state) => {
  return getSession(state).get('current_waypoint');
};

export const getCurrentProgress = (state) => {
  return getBlock(state).get('currentProgress');
};

export const getDisplayMap = (state) => {
  return getSession(state).get('display_map');
};

export const getWaypoints = (state) => {
  return getSession(state).get('waypoints');
};

export const getIncludeExitButton = (state) => {
  return getSession(state).get('include_exit_button') || true;
};
