import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getField } from '../../selectors';

class ButtonField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    field: PropTypes.object.isRequired,
  }

  renderInput = () => {
    const { field } = this.props;

    return (
      <a className="btn-primary" href={field.get('url')}>{field.get('label') || 'Click me'}</a>
    );
  }

  render() {
    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-input">
          {this.renderInput()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(ButtonField);
