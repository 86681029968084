import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateWarnings } from '../../actions/warnings';
import {
  getField,
  getPresignedS3UrlForVariable,
  makeGetTextFor,
} from '../../selectors';

const MaxFileSize = 100000; // 100 MB

class ImageUploadField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    presignedS3Url: PropTypes.object.isRequired,
    updateWarnings: PropTypes.func.isRequired,
    getTextFor: PropTypes.func.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  onFileUploadChange = (e) => {
    const attachment = e.target.files[0];
    const fileSize = attachment.size / 1024; // kilobytes
    const splitFilename = attachment.name.split('.');
    const fileType = splitFilename[splitFilename.length - 1];

    if (!['jpg', 'jpeg', 'png'].includes(fileType)) {
      const fileTypeValidationWarningObject = {};
      const fileTypeWarningMessage = 'Only image-type files are accepted (.png/.jpg).';
      fileTypeValidationWarningObject[this.props.id] = fileTypeWarningMessage;
      this.props.updateWarnings(fileTypeValidationWarningObject);

      e.target.value = '';
      return;
    }

    if (fileSize > MaxFileSize) {
      const filesizeValidationWarningObject = {};
      const fileSizeWarningMessage = 'Files can only be up to 100 MB in size.';
      filesizeValidationWarningObject[this.props.id] = fileSizeWarningMessage;
      this.props.updateWarnings(filesizeValidationWarningObject);

      e.target.value = '';
      return;
    }

    this.props.updateWarnings({});
    this.props.onChange({
      attachment,
      fieldId: this.props.id,
      filename: attachment.name,
      presignedS3Url: this.props.presignedS3Url,
    });

    e.target.value = '';
  }

  renderClearInputButton = () => {
    if (!this.props.value.size) return null;

    const clearInput = () => this.props.onChange(null);

    return (
      <button className="file-result__button" onClick={clearInput}>
        <i className="fas fa-times" />
        {this.props.getTextFor('remove')}
      </button>
    );
  }

  renderFileName = () => {
    let filename;

    if (this.props.value.size) {
      filename = this.props.value.get('filename');
    }

    if (!this.props.value.size) return null;

    return (
      <div className="file-result">
        <div className="file-result__name">
          <i className="far fa-file-image" />
          {filename}
        </div>
        {this.renderClearInputButton()}
      </div>
    );
  }

  renderInlineFileName = () => {
    let filename;

    if (this.props.value.size) {
      filename = this.props.value.get('filename');
    }

    if (!this.props.value.size) return null;

    return (
      <span className="file-result">
        <span className="file-result__name">
          <i className="far fa-file-image" />
          {filename}
        </span>
        {this.renderClearInputButton()}
      </span>
    );
  }

  renderInput = () => {
    const inputId = `file-upload-${this.props.id}`;

    return (
      <React.Fragment>
        <div className="file-input">
          <input
            id={inputId}
            type="file"
            onChange={this.onFileUploadChange}
            className="hidden-input"
            accept="image/jpg, image/jpeg, image/png"
            disabled={this.props.prerenderMode}
          />
          <label htmlFor={inputId}>
            {this.props.getTextFor('upload-image')}
          </label>
        </div>
        {this.renderFileName()}
      </React.Fragment>
    );
  }

  renderInlineInput = () => {
    const inputId = `file-upload-${this.props.id}`;

    return (
      <React.Fragment>
        <span className="file-input">
          <input
            id={inputId}
            type="file"
            onChange={this.onFileUploadChange}
            className="hidden-input"
            accept="image/jpg, image/jpeg, image/png"
            disabled={this.props.prerenderMode}
          />
          <label htmlFor={inputId}>
            {this.props.getTextFor('upload-image')}
          </label>
        </span>
        {this.renderInlineFileName()}
      </React.Fragment>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInlineInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <label htmlFor={`file-upload-${this.props.id}`} className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </label>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  const presignedS3Url = getPresignedS3UrlForVariable(state, field.get('immutableVariableName'));

  return {
    field,
    presignedS3Url,
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateWarnings: (data) => dispatch(updateWarnings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadField);
