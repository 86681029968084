import { isConstant, evaluateConstant } from './constancy';
import {
  knownValuePresentFor,
  knownValueFor,
  onscreenValuePresentFor,
  getOnscreenValueForVariable,
  definitionPresentFor,
  definitionFor,
} from '../selectors';
import { evaluateDefinition } from './definition';

// this is adapted from app/lib/runtime.rb
export const runtimeValue = (state, reference) => {
  // check for constancy first:
  if (isConstant(reference)) {
    return evaluateConstant(reference);
  }

  // check for a known value next:
  if (knownValuePresentFor(state, reference)) {
    return knownValueFor(state, reference);
  }

  // check for an onscreen value next:
  if (onscreenValuePresentFor(state, reference)) {
    return getOnscreenValueForVariable(state, reference);
  }

  // check for a definition next:
  if (definitionPresentFor(state, reference)) {
    const definition = definitionFor(state, reference);
    return evaluateDefinition(state, definition);
  }

  // got nothin':
  return null;
}
