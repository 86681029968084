import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProgress } from '../selectors';

class ProgressBar extends React.Component {
  static propTypes = {
    currentProgress: PropTypes.number,
  }

  static defaultProps = {
    currentProgress: null,
  }

  render() {
    if (!this.props.currentProgress) return null;

    return (
      <div className="progress-bar">
        <div className="progress-bar__wrapper">
          <div className="progress-bar__current" style={{ width: `${this.props.currentProgress}%`}}>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProgress: getCurrentProgress(state),
  };
};

export default connect(mapStateToProps)(ProgressBar);
