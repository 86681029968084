// TODO: We should switch out Dropdown to use our new ReactSelectWrapper for consistency with other field types.

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getChoicesForField,
} from '../../selectors';
import Dropdown from '../Dropdown';

class DropdownField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    choices: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
  }

  options = () => {
    return this.props.choices.map((choice) => choice.toJS());
  }

  clearSelection = () => {
    this.props.onChange(null);
  }

  renderInput = () => {
    return (
      <React.Fragment>
        <Dropdown
          options={this.options()}
          selectedValue={this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.prerenderMode}
        />
      </React.Fragment>
    );
  }

  renderClearButton = () => {
    if (this.props.required) return null;

    return (
      <div className="page-block__field-input__clear">
        <button className="btn-secondary" onClick={this.clearSelection}>
          <i className="fa fa-times" />
          Clear
        </button>
      </div>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <span className="page-block__inline-wrapper">
          {this.props.renderRequired()}
          {this.renderInput()}
          {this.props.renderWarnings()}
        </span>
      );
    }

    return (
      <div className="page-block__field-wrapper" onKeyDown={this.props.listenForSubmit}>
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.renderClearButton()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const choices = getChoicesForField(state, ownProps.id);

  return {
    choices,
  };
};

export default connect(mapStateToProps)(DropdownField);
