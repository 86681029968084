// app/javascript/packs/index.js

// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import App from '../components/App';

document.addEventListener('DOMContentLoaded', () => {

  const mountNodeId = 'data-node';
  const mountNode = document.getElementById(mountNodeId);
  const entrypointContainer = document.querySelector('.systemd-container');

  const prerenderMode = mountNode.getAttribute('data-prerender_mode');

  const prerenderSessionString = mountNode.getAttribute('data-prerendered_session');
  let prerenderSessionJson;

  if (prerenderSessionString) {
    prerenderSessionJson = JSON.parse(prerenderSessionString);
  }

  if (prerenderMode === 'true') {
    // path tweezer goes down
    const rts = ReactDOMServer.renderToString(<App prerenderSessionJson={prerenderSessionJson} />);
    entrypointContainer.innerHTML = rts;
  } else if (entrypointContainer.hasChildNodes()) {
    // path that a human user requesting a session with a prerender goes down
    ReactDOM.hydrate(
      <App prerenderSessionJson={prerenderSessionJson} />,
      entrypointContainer,
    );
  } else {
    // all other paths
    ReactDOM.render(
      <App prerenderSessionJson={prerenderSessionJson} />,
      entrypointContainer,
    );
  }
});
