import { fromJS } from 'immutable';
import { UPDATE_WARNINGS } from '../actions/warnings';
import { emptyMap } from '../utils/emptiness';

const updateWarnings = (state, action) => {
  return fromJS(action.data);
};

const warningsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_WARNINGS:
      return updateWarnings(state, action);
    default:
      return state || emptyMap;
  }
};

export default warningsReducer;
