import { fromJS } from 'immutable';
import { UPDATE_ASSEMBLED_DOCUMENTS } from '../actions/assembledDocuments';
import { emptyMap } from '../utils/emptiness';

const updateAssembledDocuments = (state, action) => {
  return state.merge(fromJS(action.data));
};

const assembledDocumentsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ASSEMBLED_DOCUMENTS:
      return updateAssembledDocuments(state, action);
    default:
      return state || emptyMap;
  }
};

export default assembledDocumentsReducer;
