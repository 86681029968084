import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getField,
} from '../../selectors';
import { emptyList } from '../../utils/emptiness';
import ReactSelectWrapper from '../ReactSelectWrapper';

class NativeTableSelector extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  handleChange = (e) => {
    // This is needed if the user intentionally clears the value.
    this.props.onChange(e === null ? null : e.value);
  }

  options = () => {
    return (this.props.field.get('tableOptions') || emptyList).map((option) => {
      return { label: option.get('label'), value: option.get('value') };
    }).sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    }).toJS();
  }

  getSelectedValue = () => {
    if (!this.props.value) return null;

    // We use JSON.stringify because we're testing Object equality. If this is a perf hit in larger tables we can attempt something else.
    return this.options().find((option) => JSON.stringify(option.value) === JSON.stringify(this.props.value.toJS()));
  }

  renderInput = () => {
    return (
      <React.Fragment>
        <ReactSelectWrapper
          options={this.options()}
          selectedValue={this.getSelectedValue()}
          onChange={this.handleChange}
          disabled={this.props.prerenderMode}
        />
      </React.Fragment>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(NativeTableSelector);
