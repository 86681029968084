import dayjs from 'dayjs';

export const timeOnly = (something) => {
  if (typeof something === 'string') {
    // str is HH:MM in 24 hr time
    const split = something.split(':');
    const hours = split[0];
    const minutes = split[1];
    return dayjs(`1992-03-30T${hours}:${minutes}`);
  }
  return dayjs(something);
};
