import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getField,
  getChoicesForField,
  getOnscreenValueForVariable,
} from '../../selectors';
import CheckboxChoice from '../choices/CheckboxChoice';
import { emptyMap } from '../../utils/emptiness';

class Checkboxes extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    choices: PropTypes.object.isRequired,
    onscreenValue: PropTypes.object,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    onscreenValue: emptyMap,
  }

  handleChange = (e) => {
    const onscreenValue = this.props.onscreenValue || emptyMap;
    const optionValueBeforeChange = onscreenValue.get(e);
    const oppositeValue = !optionValueBeforeChange;
    const newOnscreen = onscreenValue.set(e, oppositeValue);
    this.props.onChange(newOnscreen);
  }

  renderInput = () => {
    const choices = this.props.choices.sortBy((choice) => choice.get('order')).map((choice) => {
      const choiceId = choice.get('id');

      return (
        <CheckboxChoice
          id={choiceId}
          onChange={this.handleChange}
          key={choiceId}
          listenForSubmit={this.props.listenForSubmit}
          disabled={this.props.prerenderMode}
        />
      );
    });
    return (
      <fieldset className="checkbox-inputs">
        <legend className="sr-only">Choices:</legend>
        {choices}
      </fieldset>
    );
  }

  renderInlineInput = () => {
    const choices = this.props.choices.sortBy((choice) => { return choice.get('order'); }).map((choice) => {
      const choiceId = choice.get('id');

      return (
        <span key={choiceId} className="checkbox-inputs__input">
          <CheckboxChoice
            id={choiceId}
            onChange={this.handleChange}
            key={choiceId}
            listenForSubmit={this.props.listenForSubmit}
          />
        </span>
      );
    });
    return (
      <fieldset className="checkbox-inputs">
        <legend className="sr-only">Choices:</legend>
        {choices}
      </fieldset>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInlineInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  const choices = getChoicesForField(state, ownProps.id);
  const onscreenValue = getOnscreenValueForVariable(state, field.get('immutableVariableName'));

  return {
    choices,
    onscreenValue,
  };
};

export default connect(mapStateToProps)(Checkboxes);
