import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBlock } from '../../selectors';
import Field from '../Field';
import Submit from './Submit';
import MarkdownRenderer from '../MarkdownRenderer';

class Info extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
  }

  renderSubquestion = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.block.get('subquestion')} />
      </React.Fragment>
    );
  }

  renderSubmit = () => {
    return (
      <div>
        <Submit />
      </div>
    );
  }

  renderFields = () => {
    const fields = this.props.block.get('fields').map((fieldId) => {
      return (
        <Field id={fieldId} key={fieldId} />
      );
    });
    return (
      <div>
        {fields}
      </div>
    );
  }

  render() {
    return (
      <div className="page-block animate__animated" role="form">
        {this.renderSubquestion()}
        {this.renderFields()}
        {this.renderSubmit()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  return {
    block,
  };
};

export default connect(mapStateToProps)(Info);
