import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import InlineMarkdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import Field from './Field';

class MarkdownRenderer extends React.Component {
  // wrapper for ReactMarkdown

  static propTypes = {
    source: PropTypes.string.isRequired,
    inline: PropTypes.bool,
  }

  static defaultProps = {
    inline: false,
  }

  // TODO: We need to find a way to include rel="noopener noreferrer"
  // https://github.com/rexxars/react-markdown/issues/413
  addTargetBlank = () => '_blank';

  render() {
    if (this.props.inline) {
      const MyParagraph = ({ children, ...props }) => (
        <div {...props} className="inline-wrapper__item">
          {children}
        </div>
      );

      const MyHref = ({ children, ...props }) => (
        <a {...props} target="_blank" rel="noopener noreferrer">{children}</a>
      );

      return (
        <InlineMarkdown
          className="inline-wrapper"
          options={{
            overrides: {
              p: {
                component: MyParagraph,
              },
              a: {
                component: MyHref,
              },
              Field: {
                component: Field,
              },
            },
          }}
        >
          {this.props.source}
        </InlineMarkdown>
      );
    }

    // We replace tabs with spaces so that inline code blocks aren't created accidentally.
    const tabReplacedSource = (this.props.source || '').split('\n')
      .map((substr) => {
        return substr.replace(/\t/, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
          .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
      }).join('\n');

    return (
      <React.Fragment>
        <ReactMarkdown
          source={tabReplacedSource}
          linkTarget={this.addTargetBlank}
          escapeHtml={false}
        />
      </React.Fragment>
    );
  }
}

export default MarkdownRenderer;
