import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
  getSessionKey,
  getSessionId,
  makeGetTextFor,
} from '../../selectors';
import MarkdownRenderer from '../MarkdownRenderer';
import LawpayCheckout from '../LawpayCheckout';
import CompletedPayment from './CompletedPayment';
import { submitLawpayPaymentRequest } from '../../api';
import { loadNewState } from '../../actions';
import { stopWorking } from '../../actions/ui';
import { formatAsCurrency } from '../../utils';
import { isValidEmail } from '../../utils/simple_email_validation';

class Lawpay extends React.Component {
  static propTypes = {
    sessionId: PropTypes.string.isRequired,
    sessionKey: PropTypes.string.isRequired,
    block: PropTypes.object.isRequired,
    loadNewState: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  onToken = (token, email) => {
    if (!isValidEmail(email)) {
      this.props.stopWorking();
      this.setState({
        error: this.props.getTextFor('validations.email'),
      });
      return;
    }
    const { sessionId, sessionKey } = this.props;
    const blockId = this.props.block.get('id');
    const success = (object) => {
      this.props.loadNewState(object);
    };
    const failure = (message) => {
      this.props.stopWorking();
      this.setState({ error: message });
    };
    submitLawpayPaymentRequest(sessionId, sessionKey, blockId, token, success, failure);
  }

  renderSubquestion = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.block.get('subquestion')} />
      </React.Fragment>
    );
  }

  renderCheckoutForm = () => {
    return (
      <LawpayCheckout
        onToken={this.onToken}
        paymentAmountDisplay={formatAsCurrency(this.props.block.get('payment_amount'), this.props.block.get('payment_currency'))}
      />
    );
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div className="page-block__submit--lawpay animate__animated" role="form">
          {this.state.error}
        </div>
      );
    }
    return '';
  }

  render() {
    if (this.props.block.get('completed_payment')) {
      return (
        <CompletedPayment />
      );
    }
    return (
      <div className="page-block page-block__lawpay animate__animated">
        {this.renderSubquestion()}
        {this.renderCheckoutForm()}
        {this.renderError()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  const sessionId = getSessionId(state);
  const sessionKey = getSessionKey(state);
  return {
    block,
    sessionId,
    sessionKey,
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lawpay);
