import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import {
  getField,
  getSessionId,
  getSessionKey,
  getActivated,
  makeGetTextFor,
} from '../../selectors';
import ReactSelectWrapper from '../ReactSelectWrapper';
import { googleSearchRequest } from '../../api';

class GoogleTableSelector extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    sessionId: PropTypes.string.isRequired,
    sessionKey: PropTypes.string.isRequired,
    activated: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  // defaultOptions lets us cache the returned value from the async call so that we can continue presenting
  // it to the user even if they click away, or clear, their input.
  constructor(props) {
    super(props);

    this.state = {
      defaultOptions: null,
    };
  }

  handleChange = (e) => {
    // This is needed if the user intentionally clears the value.
    this.props.onChange(e === null ? null : e.value);
  }

  getGoogleData = (inputValue, callback) => {
    const { sessionId, sessionKey, field } = this.props;
    const tableOptions = field.get('tableOptions');

    const success = (searchResults) => {
      const tableKeyColumnName = tableOptions.get('key_column_name');

      const defaultOptions = searchResults.map((rowValue) => {
        const label = rowValue[tableKeyColumnName];

        return { label, value: rowValue };
      });

      this.setState({ defaultOptions });

      return callback(defaultOptions);
    };

    const failure = (message) => {
      if (this.props.activated) {
        alert(this.props.getTextFor('validations.there-was-an-error-refresh'));
      } else {
        alert(`${this.props.getTextFor('validations.there-was-an-error')}: ${message}`);
      }

      return callback([]);
    };

    return googleSearchRequest(sessionId, sessionKey, inputValue, tableOptions.get('immutable_variable_name'), success, failure);
  }

  getSelectedValue = () => {
    const { value, field } = this.props;
    const tableOptions = field.get('tableOptions');

    if (!value) return null;

    const tableKeyColumnName = tableOptions.get('key_column_name');
    const label = value.get(tableKeyColumnName);

    return { label, value };
  }

  renderInput = () => {
    // See https://github.com/JedWatson/react-select/issues/614 for info on debouncing/throttling react-select async data
    const throttledGetGoogleData = throttle((inputValue, callback) => this.getGoogleData(inputValue, callback), 500);

    return (
      <React.Fragment>
        <ReactSelectWrapper
          selectedValue={this.getSelectedValue()}
          onChange={this.handleChange}
          asyncOptions={throttledGetGoogleData}
          defaultOptions={this.state.defaultOptions}
          disabled={this.props.prerenderMode}
          async
        />
      </React.Fragment>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  const sessionId = getSessionId(state);
  const sessionKey = getSessionKey(state);
  const activated = getActivated(state);

  return {
    field,
    sessionId,
    sessionKey,
    activated,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(GoogleTableSelector);
