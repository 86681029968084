import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBlock } from '../../selectors';
import MarkdownRenderer from '../MarkdownRenderer';
import StripeCheckout from '../StripeCheckout';
import CompletedPayment from './CompletedPayment';
import { formatAsCurrency } from '../../utils';

class Stripe extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
  }

  renderSubquestion = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.block.get('subquestion')} />
      </React.Fragment>
    );
  }

  renderCheckoutForm = () => {
    return (
      <StripeCheckout
        clientSecret={this.props.block.get('stripe_secret')}
        paymentAmountDisplay={formatAsCurrency(this.props.block.get('payment_amount'), this.props.block.get('payment_currency'))}
      />
    );
  }

  render() {
    if (this.props.block.get('completed_payment')) {
      return (
        <CompletedPayment />
      );
    }
    return (
      <div className="page-block page-block__stripe animate__animated" role="form">
        {this.renderSubquestion()}
        {this.renderCheckoutForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  return {
    block,
  };
};

export default connect(mapStateToProps)(Stripe);
