import { combineReducers } from 'redux-immutable';
import { LOAD_NEW_STATE } from '../actions';
import onscreenReducer from './onscreen';
import warningsReducer from './warnings';
import uiReducer from './ui';
import assembledDocumentsReducer from './assembledDocuments';
import { emptyMap } from '../utils/emptiness';
import normalizeAppSession from '../normalizers/app_session';

const noOpReducer = (state) => {
  return typeof state === 'undefined' ? emptyMap : state;
};

const combinedReducer = combineReducers({
  onscreen: onscreenReducer,
  warnings: warningsReducer,
  ui: uiReducer,

  block: noOpReducer,
  choices: noOpReducer,
  fields: noOpReducer,
  session: noOpReducer,
  blockId: noOpReducer,
  sessionId: noOpReducer,
  variables: noOpReducer,
  presignedS3Urls: noOpReducer,
  assembledDocuments: assembledDocumentsReducer,
  markdownAttachments: noOpReducer,
  wordAttachments: noOpReducer,
  pdfAttachments: noOpReducer,
  fileAttachments: noOpReducer,
  fieldIdsByVariableName: noOpReducer,
  styles: noOpReducer,
  activated: noOpReducer,
  selectedCurrency: noOpReducer,
  selectedLanguage: noOpReducer,
  saveAndContinueEnabled: noOpReducer,
  clientPortalEndpoint: noOpReducer,
  includeClAttribution: noOpReducer,
  loopVariableInProgress: noOpReducer,
  translations: noOpReducer,
});

const loadNewState = (oldState, action) => {
  return normalizeAppSession(oldState, action.state);
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case LOAD_NEW_STATE:
      return loadNewState(state || emptyMap, action);
    default:
      return combinedReducer(state || emptyMap, action);
  }
};

export default rootReducer;
