import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

import {
  getWaypoints,
  getSession,
  getSubmissibleOnscreenVariables,
  makeGetTextFor,
  getBlockId,
  getBlock,
} from '../selectors';
import { loadNewState } from '../actions';
import { updateUi } from '../actions/ui';
import { updateWarnings } from '../actions/warnings';
import { goToWaypointRequest } from '../api';
import { emptyMap } from '../utils/emptiness';
import transformFileUploadVariables from '../utils/file_upload_variable_transformer';

// Number of pixels for which we switch between side display and top-collapsible display.
const BREAK_MEDIUM = 1100;

class WaypointSidebar extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    waypoints: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    loadNewState: PropTypes.func.isRequired,
    updateWarnings: PropTypes.func.isRequired,
    blockId: PropTypes.string.isRequired,
    submissibleOnscreenVariables: PropTypes.object.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      showTop: window.innerWidth < BREAK_MEDIUM,
      showingHamburger: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.throttledResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.throttledResize);
  }

  throttledResize = throttle(() => this.calculatePosition(), 100);

  calculatePosition = () => {
    this.setState({ showTop: window.innerWidth < BREAK_MEDIUM });
  }

  goToWaypoint = (waypointId, rewinding) => {
    const sessionId = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const { getTextFor, blockId } = this.props;
    const variables = this.props.submissibleOnscreenVariables.toJS();

    // Same logic as in the Submit component.

    transformFileUploadVariables(variables, getTextFor).then((promiseValues) => {
      const { transformedVariables, uploadValidationsWarningObject } = promiseValues;

      if (Object.keys(uploadValidationsWarningObject).length > 0) {
        this.props.updateWarnings(uploadValidationsWarningObject);
      } else {
        const success = (object) => this.props.loadNewState(object);
        const failure = (message) => alert(message);

        goToWaypointRequest(sessionId, sessionKey, waypointId, rewinding, transformedVariables, blockId, success, failure);
      }
    });
  }

  toggleShowingHamburger = () => {
    this.setState((currState) => ({ showingHamburger: !currState.showingHamburger }));
  }

  renderSide = () => {
    const { waypoints, block } = this.props;
    const currentBlockType = block.get('type');

    const activeWaypoint = waypoints.filter((waypoint) => waypoint.get('visited')).last() || emptyMap;
    const activeWaypointId = activeWaypoint.get('id');

    const waypointElements = waypoints.map((waypoint) => {
      const waypointId = waypoint.get('id');
      let classNames = 'waypoints__waypoint';
      let onWaypointClick = () => this.goToWaypoint(waypointId, waypoint.get('visited'));

      if (waypoint.get('visited')) {
        const isActiveWaypoint = activeWaypointId === waypointId;
        classNames += isActiveWaypoint ? ' waypoints__waypoint--active' : ' waypoints__waypoint--visited';
      } else if (currentBlockType === 'final') {
        classNames += ' waypoints__waypoint--disabled';
        onWaypointClick = () => {};
      }

      return (
        <div
          className={classNames}
          key={waypointId}
          onClick={onWaypointClick}
        >
          {waypoint.get('waypoint_name')}&nbsp;
        </div>
      );
    });

    return (
      <div className="waypoints">
        <div className="waypoints__wrapper">
          {waypointElements}
        </div>
      </div>
    );
  }

  renderTop = () => {
    const { waypoints } = this.props;

    const activeWaypoint = waypoints.filter((waypoint) => waypoint.get('visited')).last() || emptyMap;
    const activeWaypointId = activeWaypoint.get('id');
    let activeWaypointName;

    const waypointElements = waypoints.map((waypoint) => {
      const waypointId = waypoint.get('id');
      let classNames = 'waypoints__waypoint waypoints__waypoint--top';

      if (waypoint.get('visited')) {
        const isActiveWaypoint = activeWaypointId === waypointId;
        classNames += isActiveWaypoint ? ' waypoints__waypoint--active' : ' waypoints__waypoint--visited';

        if (isActiveWaypoint) activeWaypointName = waypoint.get('waypoint_name');
      }

      const onWaypointClick = () => {
        this.goToWaypoint(waypointId, waypoint.get('visited'));
        this.toggleShowingHamburger();
      };

      return (
        <div
          className={classNames}
          key={waypointId}
          onClick={onWaypointClick}
        >
          {waypoint.get('waypoint_name')}&nbsp;
        </div>
      );
    });

    const waypointsHamburgerElement = this.state.showingHamburger ? (
      <div className="waypoints__hamburger">
        {waypointElements}
      </div>
    ) : null;

    return (
      <div className="waypoints">
        <div className="waypoints__wrapper">
          <div className="waypoints__top" onClick={this.toggleShowingHamburger}>
            <span>{activeWaypointName}</span>
            <i className="fa fa-bars" />
          </div>
          {waypointsHamburgerElement}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.waypoints.size === 0) return null;

    if (this.state.showTop) return this.renderTop();

    return this.renderSide();
  }
}

const mapStateToProps = (state) => {
  return {
    block: getBlock(state),
    blockId: getBlockId(state),
    waypoints: getWaypoints(state),
    session: getSession(state),
    submissibleOnscreenVariables: getSubmissibleOnscreenVariables(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUi: (data) => dispatch(updateUi(data)),
  loadNewState: (data) => dispatch(loadNewState(data)),
  updateWarnings: (data) => dispatch(updateWarnings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaypointSidebar);
