import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import {
  getPdfAttachment,
  getAssembledDocumentsForTemplate,
  makeGetTextFor,
} from '../selectors';
import { isIosChrome, isIosSafari } from '../utils/agent';

class BlockPdfAttachment extends React.Component {
  static propTypes = {
    attachmentId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    attachment: PropTypes.object.isRequired,
    assembledDocuments: PropTypes.object.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  renderFailedAttachment = () => {
    return (
      <React.Fragment>
        <div>{this.props.getTextFor('validations.problem-assembling')} {this.props.attachment.get('attachmentFilename')}.</div>
        <div>{this.props.assembledDocuments.get('message')}</div>
      </React.Fragment>
    );
  }

  renderPendingAttachment = () => {
    return (
      <div className="attachment-item__pending">
        <span>
          <i className="far fa-file-pdf"></i>
          {this.props.attachment.get('attachmentFilename')}
        </span>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    );
  }

  saveFile = () => {
    const fileUrl = this.props.assembledDocuments.get('url');
    const attachmentFilename = this.props.attachment.get('attachmentFilename');

    fetch(fileUrl)
      .then((resp) => resp.blob())
      .then((data) => FileSaver.saveAs(data, `${attachmentFilename}.pdf`));
  }

  // NOTE: without using FileSaver, the downloaded document won't have the correct filename
  // (will have the filename of the template, not the custom attachment filename).
  renderCompletedAttachment = () => {
    const isFileSaverSupported = (!!(new Blob())) && (!isIosChrome()) && (!isIosSafari());
    const fileUrl = this.props.assembledDocuments.get('url');
    const attachmentFilename = this.props.attachment.get('attachmentFilename');
    const saveFileElement = isFileSaverSupported ? (
      <button onClick={this.saveFile}>
        <i className="far fa-file-pdf"></i>
        {attachmentFilename}
      </button>
    ) : (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <i className="far fa-file-pdf"></i>
        <span className="attachment-item__file-name">
          {attachmentFilename}
        </span>
      </a>
    );
    return (
      <div className="attachment-item__complete">
        {saveFileElement}
      </div>
    );
  }

  renderAttachment = () => {
    switch (this.props.assembledDocuments.get('status')) {
      case 'processing':
        return this.renderPendingAttachment();
      case 'completed':
        return this.renderCompletedAttachment();
      case 'failed':
        return this.renderFailedAttachment();
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="attachment-item">
        {this.renderAttachment()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { attachmentId } = ownProps;
  const attachment = getPdfAttachment(state, attachmentId);
  const globalTemplateId = attachment.get('globalTemplateId');
  const assembledDocuments = getAssembledDocumentsForTemplate(state, globalTemplateId);

  return {
    attachment,
    assembledDocuments,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(BlockPdfAttachment);
