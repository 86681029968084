import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getChoicesForField,
  makeGetTextFor,
} from '../../selectors';
import ReactSelectWrapper from '../ReactSelectWrapper';

class ComboboxField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    choices: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    getTextFor: PropTypes.func.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  handleChange = (e) => {
    // This is needed if the user intentionally clears the value.
    this.props.onChange(e === null ? null : e.value);
  }

  options = () => {
    return this.props.choices.map((choice) => {
      return { value: choice.get('value'), label: choice.get('label') };
    }).toJS();
  }

  getSelectedValue = () => {
    const { value } = this.props;

    if (!value) return null;

    const foundOption = this.options().find((option) => option.value === value);

    // If they created a new option, foundOption will be undefined so we return their custom option.
    return foundOption || { label: value, value };
  }

  renderInput = () => {
    return (
      <React.Fragment>
        <ReactSelectWrapper
          options={this.options()}
          selectedValue={this.getSelectedValue()}
          onChange={this.handleChange}
          creatable
          placeholder={this.props.getTextFor('select-one-or-create')}
          disabled={this.props.prerenderMode}
        />
      </React.Fragment>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const choices = getChoicesForField(state, ownProps.id);

  return {
    choices,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(ComboboxField);
