import React from 'react';
import PropTypes from 'prop-types';

class FieldValidationWarningInline extends React.Component {
  static propTypes = {
    warning: PropTypes.string,
  }

  static defaultProps = {
    warning: null,
  }

  render() {
    const { warning } = this.props;
    if (warning) {
      return (
        <span className="required-alert">
          <span className="required-alert__badge animate__animated">
            <i className="fas fa-exclamation-triangle" />
            {this.props.warning}
          </span>
        </span>
      );
    }
    return '';
  }
}

export default FieldValidationWarningInline;
