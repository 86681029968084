import dayjs from 'dayjs';
import { timeOnly } from '../../utils/timeOnly';
import { isNumeric } from '../constancy';

const numberGreaterOrEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return parseFloat(lhs, 10) >= parseFloat(rhs, 10);
  },
};

const numberEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return parseFloat(lhs, 10) === parseFloat(rhs, 10);
  },
};

const numberNotEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return parseFloat(lhs, 10) !== parseFloat(rhs, 10);
  },
};

const numberGreater = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return parseFloat(lhs, 10) > parseFloat(rhs, 10);
  },
};

const numberLess = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return parseFloat(lhs, 10) < parseFloat(rhs, 10);
  },
};

const numberLessOrEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return parseFloat(lhs, 10) <= parseFloat(rhs, 10);
  },
};

const numberMultiply = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) && (rhs === null)) {
      return null;
    }
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return lhs;
    }
    return parseFloat(lhs, 10) * parseFloat(rhs, 10);
  },
};

const numberAdd = {
  arity: 2,
  operate: (lhs, rhs) => {
    let effectiveLhs;
    let effectiveRhs;

    if (lhs === true) {
      effectiveLhs = 1;
    } else if (lhs === false) {
      effectiveLhs = 0;
    } else {
      effectiveLhs = lhs;
    }

    if (rhs === true) {
      effectiveRhs = 1;
    } else if (rhs === false) {
      effectiveRhs = 0;
    } else {
      effectiveRhs = rhs;
    }

    if ((effectiveLhs === null) && (effectiveRhs === null)) {
      return null;
    }
    if (effectiveLhs === null) {
      return effectiveRhs;
    }
    if (effectiveRhs === null) {
      return effectiveLhs;
    }
    return parseFloat(effectiveLhs, 10) + parseFloat(effectiveRhs, 10);
  },
};

const numberSubtract = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) && (rhs === null)) {
      return null;
    }
    if (lhs === null) {
      return (-1 * parseFloat(rhs, 10));
    }
    if (rhs === null) {
      return lhs;
    }
    return parseFloat(lhs, 10) - parseFloat(rhs, 10);
  },
};

const numberDivide = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) && (rhs === null)) {
      return null;
    }
    if (lhs === null) {
      return 0;
    }
    if (rhs === null) {
      return lhs;
    }
    return parseFloat(lhs, 10) / parseFloat(rhs, 10);
  },
};

const numberExponentiate = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) && (rhs === null)) {
      return null;
    }
    if (lhs === null) {
      return 0;
    }
    if (rhs === null) {
      return lhs;
    }
    return parseFloat(lhs, 10) ** parseFloat(rhs, 10);
  },
};

const stringEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return lhs === rhs;
  },
};

const stringNotEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return lhs !== rhs;
  },
};

const booleanIs = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return !!lhs;
  },
};

const booleanEquals = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return null;
    }
    if (rhs === null) {
      return null;
    }
    return lhs === rhs;
  },
};

const booleanAnd = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === false || rhs === false) {
      return false;
    }
    if (lhs === true && rhs === true) {
      return true;
    }
    return null;
  },
};

const booleanOr = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === true || rhs === true) {
      return true;
    }
    if (lhs === false && rhs === false) {
      return false;
    }
    return null;
  },
};

const booleanNot = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return !lhs;
  },
};

const dictionaryAccess = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }

    return lhs.get(rhs) || null;
  },
};

const booleanValuedDictionaryAllTrue = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }

    return lhs.every((val) => {
      return !!val;
    });
  },
};

const booleanValuedDictionaryAllFalse = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }

    return lhs.every((val) => {
      return !val;
    });
  },
};

const booleanValuedDictionaryAnyTrue = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }

    return lhs.some((val) => {
      return !!val;
    });
  },
};

const booleanValuedDictionaryAnyFalse = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }

    return lhs.some((val) => {
      return !val;
    });
  },
};

const hasTruthyValueAtKey = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }

    return !!(lhs.get(rhs));
  },
};

const hasFalsyValueAtKey = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }

    return !(lhs.get(rhs));
  },
};

const booleanValuedDictionaryAsCommaDelimitedListOfTrueValues = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    const filtered = lhs.filter((value) => {
      return value === true;
    });
    const mapped = filtered.map((value, key) => {
      return key;
    });
    return mapped.join(', ');
  },
};

const typecastSystemdRowAttributeAs = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    switch (rhs) {
      case 'string':
        return lhs;
      case 'number':
        return parseFloat(lhs, 10);
      case 'date':
        return new Date(lhs).toISOString().split('T')[0];
      case 'boolean':
        if (['true', 'True'].includes(lhs)) return true;
        if (['false', 'False'].includes(lhs)) return false;
        return null;
      default:
        return rhs;
    }
  },
};

const isUndefined = {
  arity: 1,
  operate: (lhs) => {
    return lhs === null;
  },
};

const isDefined = {
  arity: 1,
  operate: (lhs) => {
    return lhs !== null;
  },
};

const stringJoinWithSpace = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null && rhs === null) return null;

    return `${lhs || ''} ${rhs || ''}`;
  },
};

const stringJoinWithoutSpace = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null && rhs === null) return null;

    return `${lhs || ''}${rhs || ''}`;
  },
};

const numberFormatDecimalPrecision = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return (parseFloat(lhs)).toFixed(parseFloat(rhs));
  },
};

const yearsAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10), 'year');
  },
};

const monthsAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10), 'month');
  },
};

const daysAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10), 'day');
  },
};

const minutesAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = timeOnly(rhs);
    return timeOnly(asDayjs.add(parseInt(lhs, 10), 'minute'));
  },
};

const hoursAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = timeOnly(rhs);
    return timeOnly(asDayjs.add(parseInt(lhs, 10), 'hour'));
  },
};

const minutesBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = timeOnly(rhs);
    return timeOnly(asDayjs.add(parseInt(lhs, 10) * -1, 'minute'));
  },
};

const hoursBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = timeOnly(rhs);
    return timeOnly(asDayjs.add(parseInt(lhs, 10) * -1, 'hour'));
  },
};

const yearsBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10) * -1, 'year');
  },
};

const monthsBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10) * -1, 'month');
  },
};

const daysBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10) * -1, 'day');
  },
};

const dateEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return dayjs(lhs).isSame(dayjs(rhs));
  },
};

const dateLess = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return dayjs(lhs).isBefore(dayjs(rhs));
  },
};

const dateLessOrEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return dayjs(lhs).isBefore(dayjs(rhs)) || dayjs(lhs).isSame(dayjs(rhs));
  },
};

const dateGreater = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return dayjs(lhs).isAfter(dayjs(rhs));
  },
};

const dateGreaterOrEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return dayjs(lhs).isAfter(dayjs(rhs)) || dayjs(lhs).isSame(dayjs(rhs));
  },
};

const timeEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return timeOnly(lhs).isSame(timeOnly(rhs));
  },
};

const timeLess = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return timeOnly(lhs).isBefore(timeOnly(rhs));
  },
};

const timeLessOrEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return timeOnly(lhs).isBefore(timeOnly(rhs)) || timeOnly(lhs).isSame(timeOnly(rhs));
  },
};

const timeAsHhMmAmpm = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return timeOnly(lhs).format('hh:mm A');
  },
};

const timeGreater = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return timeOnly(lhs).isAfter(timeOnly(rhs));
  },
};

const timeGreaterOrEqual = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }
    return timeOnly(lhs).isAfter(timeOnly(rhs)) || timeOnly(lhs).isSame(timeOnly(rhs));
  },
};

const stringAsDateIso8601 = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    const asDayjs = dayjs(lhs);
    if (asDayjs.isValid()) {
      return asDayjs;
    }
    return null;
  },
};

const stringAsDateMmddyyyy = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    const asDayjs = dayjs(lhs, 'MM/DD/YYYY');
    if (asDayjs.isValid()) {
      return asDayjs;
    }
    return null;
  },
};

const stringAsNumber = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    if (isNumeric(lhs)) {
      return parseFloat(lhs);
    }
    return null;
  },
};

const stringLength = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return lhs.length;
  },
};

const characterAt = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return lhs.charAt(rhs - 1);
  },
};

const dateFormatDdmmyyyy = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('DD/MM/YYYY');
  },
};

const dateFormatMmddyyyy = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('MM/DD/YYYY');
  },
};

const dateFormatYyyymmdd = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('YYYY/MM/DD');
  },
};

const dateFormatMonthName = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('MMMM');
  },
};

const dateFormatDd = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('DD');
  },
};

const dateFormatMm = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('MM');
  },
};

const dateFormatMdyMonthName = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('MMMM D, YYYY');
  },
};

const dateFormatMdyMonthNameDayName = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('dddd, MMMM D, YYYY');
  },
};

const unsupported = {
  arity: 1,
  operate: (lhs, rhs) => {
    // this operator is not currently supported in JS
    return null;
  },
};

const dateFormatYyyy = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return dayjs(lhs).format('YYYY');
  },
};

const stringToAllCaps = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return lhs.toUpperCase();
  },
};

const stringToTitleCase = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return lhs
      .split(' ')
      .map((w) => { return w[0].toUpperCase() + w.substr(1).toLowerCase(); })
      .join(' ');
  },
};

const toLowercase = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return lhs.toLowerCase();
  },
};

const stringCaseSensitivelyIncludes = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return lhs.includes(rhs);
  },
};

const stringCaseInsensitivelyIncludes = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return lhs.toLowerCase().includes(rhs.toLowerCase());
  },
};

const businessDaysAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    let daysRemaining = lhs;
    let currentDate = rhs;
    while (daysRemaining > 0) {
      currentDate = currentDate.add(1, 'day');
      const dayOfWeek = currentDate.day();
      // saturday is 6 and sunday is 0
      if ((dayOfWeek !== 0) && (dayOfWeek !== 6)) {
        daysRemaining -= 1;
      }
    }
    return currentDate;
  },
};

const businessDaysBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return rhs;
    }
    if (rhs === null) {
      return null;
    }
    let daysRemaining = lhs;
    let currentDate = rhs;
    while (daysRemaining > 0) {
      currentDate = currentDate.add(-1, 'day');
      const dayOfWeek = currentDate.day();
      // saturday is 6 and sunday is 0
      if ((dayOfWeek !== 0) && (dayOfWeek !== 6)) {
        daysRemaining -= 1;
      }
    }
    return currentDate;
  },
};

const arrayIndex = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    if (rhs === 0) {
      return null;
    }
    if (rhs > lhs.length) {
      return null;
    }
    return lhs[rhs - 1];
  },
};

const daysSince = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return dayjs(lhs).diff(dayjs(rhs), 'day');
  },
};

const monthsSince = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return dayjs(lhs).diff(dayjs(rhs), 'month');
  },
};

const yearsSince = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) {
      return null;
    }
    return dayjs(lhs).diff(dayjs(rhs), 'year');
  },
};

const asFloat = (something) => {
  return parseFloat(something, 10);
};

const numberFloor = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return Math.floor(asFloat(lhs));
  },
};

const numberAbsoluteValue = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return Math.abs(asFloat(lhs));
  },
};

const numberCeiling = {
  arity: 1,
  operate: (lhs) => {
    if (lhs === null) {
      return null;
    }
    return Math.ceil(asFloat(lhs));
  },
};

const coalesce = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs !== null) {
      return lhs;
    }
    return rhs;
  },
};

const listIncludes = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return null;
    }
    return lhs.includes(rhs);
  },
};

const listDoesNotInclude = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null) {
      return null;
    }
    return !(lhs.includes(rhs));
  },
};

// strong null operators:
const strongNullNumberAdd = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }

    let effectiveLhs;
    let effectiveRhs;

    if (lhs === true) {
      effectiveLhs = 1;
    } else if (lhs === false) {
      effectiveLhs = 0;
    } else {
      effectiveLhs = lhs;
    }

    if (rhs === true) {
      effectiveRhs = 1;
    } else if (rhs === false) {
      effectiveRhs = 0;
    } else {
      effectiveRhs = rhs;
    }

    return parseFloat(effectiveLhs, 10) + parseFloat(effectiveRhs, 10);
  },
};

const strongNullNumberSubtract = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }

    return lhs - rhs;
  },
};

const strongNullNumberMultiply = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }

    return lhs * rhs;
  },
};

const strongNullNumberDivide = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }

    return lhs / rhs;
  },
};

const strongNullNumberExponentiate = {
  arity: 2,
  operate: (lhs, rhs) => {
    if ((lhs === null) || (rhs === null)) {
      return null;
    }

    return lhs ** rhs;
  },
};

const strongNullStringJoinWithSpace = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    return `${lhs || ''} ${rhs || ''}`;
  },
};

const strongNullStringJoinWithoutSpace = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    return `${lhs || ''}${rhs || ''}`;
  },
};

const strongNullYearsAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10), 'year');
  },
};

const strongNullMonthsAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10), 'month');
  },
};

const strongNullDaysAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10), 'day');
  },
};

const strongNullYearsBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10) * -1, 'year');
  },
};

const strongNullMonthsBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10) * -1, 'month');
  },
};

const strongNullDaysBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    const asDayjs = dayjs(rhs);
    return asDayjs.add(parseInt(lhs, 10) * -1, 'day');
  },
};

const strongNullBusinessDaysBefore = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    let daysRemaining = lhs;
    let currentDate = rhs;
    while (daysRemaining > 0) {
      currentDate = currentDate.add(-1, 'day');
      const dayOfWeek = currentDate.day();
      // saturday is 6 and sunday is 0
      if ((dayOfWeek !== 0) && (dayOfWeek !== 6)) {
        daysRemaining -= 1;
      }
    }
    return currentDate;
  },
};

const strongNullBusinessDaysAfter = {
  arity: 2,
  operate: (lhs, rhs) => {
    if (lhs === null || rhs === null) return null;

    let daysRemaining = lhs;
    let currentDate = rhs;
    while (daysRemaining > 0) {
      currentDate = currentDate.add(1, 'day');
      const dayOfWeek = currentDate.day();
      // saturday is 6 and sunday is 0
      if ((dayOfWeek !== 0) && (dayOfWeek !== 6)) {
        daysRemaining -= 1;
      }
    }
    return currentDate;
  },
};

// end strong null operators

export const getOperatorFor = (operatorIdentifier, nullDisposition) => {
  let mapping = {
    number_add: numberAdd,
    number_multiply: numberMultiply,
    number_subtract: numberSubtract,
    number_divide: numberDivide,
    number_exponentiate: numberExponentiate,

    number_floor: numberFloor,
    number_ceiling: numberCeiling,
    number_absolute_value: numberAbsoluteValue,

    number_greater_or_equal: numberGreaterOrEqual,
    number_equal: numberEqual,
    number_not_equal: numberNotEqual,
    number_greater: numberGreater,
    number_less: numberLess,
    number_less_or_equal: numberLessOrEqual,

    boolean_is: booleanIs,
    boolean_not: booleanNot,
    boolean_and: booleanAnd,
    boolean_or: booleanOr,
    boolean_equals: booleanEquals,

    string_equal: stringEqual,
    string_not_equal: stringNotEqual,
    string_join_with_space: stringJoinWithSpace,
    string_join_without_space: stringJoinWithoutSpace,
    string_length: stringLength,
    character_at: characterAt,
    string_as_date_iso_8601: stringAsDateIso8601,
    string_as_date_mmddyyyy: stringAsDateMmddyyyy,
    string_as_date_mm_dd_yyyy: stringAsDateMmddyyyy,

    number_format_decimal_precision: numberFormatDecimalPrecision,

    date_equal: dateEqual,
    date_greater: dateGreater,
    date_greater_or_equal: dateGreaterOrEqual,
    // legacy name
    date_greater_equal: dateGreaterOrEqual,
    date_less: dateLess,
    date_less_or_equal: dateLessOrEqual,

    time_equal: timeEqual,
    time_greater: timeGreater,
    time_greater_or_equal: timeGreaterOrEqual,
    time_less: timeLess,
    time_less_or_equal: timeLessOrEqual,
    time_as_hh_mm_ampm: timeAsHhMmAmpm,

    years_after: yearsAfter,
    months_after: monthsAfter,
    days_after: daysAfter,
    years_before: yearsBefore,
    months_before: monthsBefore,
    days_before: daysBefore,
    years_since: yearsSince,
    months_since: monthsSince,
    days_since: daysSince,

    hours_after: hoursAfter,
    minutes_after: minutesAfter,
    hours_before: hoursBefore,
    minutes_before: minutesBefore,

    date_format_ddmmyyyy: dateFormatDdmmyyyy,
    date_format_mmddyyyy: dateFormatMmddyyyy,
    date_format_yyyymmdd: dateFormatYyyymmdd,
    date_format_mdy_month_name: dateFormatMdyMonthName,
    date_format_mdy_month_name_day_name: dateFormatMdyMonthNameDayName,
    date_format_yyyy: dateFormatYyyy,
    date_format_month_name: dateFormatMonthName,
    date_format_dd: dateFormatDd,
    date_format_mm: dateFormatMm,

    string_as_number: stringAsNumber,
    string_to_all_caps: stringToAllCaps,
    to_uppercase: stringToAllCaps,
    to_lowercase: toLowercase,
    string_to_title_case: stringToTitleCase,

    dictionary_access: dictionaryAccess,
    table_index: dictionaryAccess,
    row_index: dictionaryAccess,
    clio_row_index: dictionaryAccess,
    google_row_index: dictionaryAccess,

    boolean_valued_dictionary_all_true: booleanValuedDictionaryAllTrue,
    boolean_valued_dictionary_any_true: booleanValuedDictionaryAnyTrue,
    boolean_valued_dictionary_all_false: booleanValuedDictionaryAllFalse,
    boolean_valued_dictionary_any_false: booleanValuedDictionaryAnyFalse,
    has_truthy_value_at_key: hasTruthyValueAtKey,
    has_falsy_value_at_key: hasFalsyValueAtKey,
    boolean_valued_dictionary_as_comma_delimited_list_of_true_values: booleanValuedDictionaryAsCommaDelimitedListOfTrueValues,
    typecast_systemd_row_attribute_as: typecastSystemdRowAttributeAs,
    is_undefined: isUndefined,
    is_defined: isDefined,

    string_case_sensitively_includes: stringCaseSensitivelyIncludes,
    string_case_insensitively_includes: stringCaseInsensitivelyIncludes,

    business_days_before: businessDaysBefore,
    business_days_after: businessDaysAfter,

    coalesce,
    coalesce_string: coalesce,
    coalesce_number: coalesce,
    coalesce_date: coalesce,
    coalesce_boolean: coalesce,
    coalesce_file: coalesce,

    list_includes: listIncludes,
    string_list_includes: listIncludes,
    number_list_includes: listIncludes,
    date_list_includes: listIncludes,
    boolean_list_includes: listIncludes,

    list_does_not_include: listDoesNotInclude,
    string_list_does_not_include: listDoesNotInclude,
    number_list_does_not_include: listDoesNotInclude,
    date_list_does_not_include: listDoesNotInclude,
    boolean_list_does_not_include: listDoesNotInclude,

    array_index: unsupported,
    array_size: unsupported,
    array_leading: unsupported,
    array_trailing: unsupported,
    array_excluding_leading: unsupported,
    array_excluding_trailing: unsupported,
    google_table_includes_row_with_key: unsupported,
    object_attribute: unsupported,
    cldb_table_index: unsupported,
    google_table_index: unsupported,
    clio_contacts_table_index: unsupported,
    clio_matters_table_index: unsupported,
    number_as_text_with_commas_separating_thousands: unsupported,
    number_as_text_switching_commas_and_periods: unsupported,
    number_as_text_with_commas_to_two_decimal_points: unsupported,
    number_as_text_with_commas_with_no_decimal: unsupported,
    date_format_yyyymmddhmstz: unsupported,
    date_format_day_as_ordinal_and_month: unsupported,
    date_format_day_as_ordinal: unsupported,
    number_as_ordinal: unsupported,
    clio_row_dictionary_access: unsupported,
  };

  const strongNullOperators = {
    number_add: strongNullNumberAdd,
    number_subtract: strongNullNumberSubtract,
    number_multiply: strongNullNumberMultiply,
    number_divide: strongNullNumberDivide,
    number_exponentiate: strongNullNumberExponentiate,
    string_join_with_space: strongNullStringJoinWithSpace,
    string_join_without_space: strongNullStringJoinWithoutSpace,
    years_after: strongNullYearsAfter,
    months_after: strongNullMonthsAfter,
    days_after: strongNullDaysAfter,
    years_before: strongNullYearsBefore,
    months_before: strongNullMonthsBefore,
    days_before: strongNullDaysBefore,
    business_days_before: strongNullBusinessDaysBefore,
    business_days_after: strongNullBusinessDaysAfter,
  };

  if (nullDisposition === 'strong') {
    mapping = { ...mapping, ...strongNullOperators };
  }

  return mapping[operatorIdentifier];
};
