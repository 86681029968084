import { evaluateExpressions } from './expressions';
import { evaluateConditions } from './conditions';

// adapted from app/lib/definition_evaluator.rb:
export const evaluateDefinition = (state, definition) => {
  const mode = definition.get('mode');

  // expressions mode:
  if (mode === 'expressions') {
    const expressions = definition.get('expressions');
    return evaluateExpressions(state, expressions);
  }

  if (mode === 'conditional') {
    const conditions = definition.get('conditions');
    const fallback = definition.get('fallback');
    return evaluateConditions(state, conditions, fallback);
  }
}
