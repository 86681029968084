import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {
  getIsWorking,
  makeGetTextFor,
} from '../selectors';
import { startWorking, stopWorking } from '../actions/ui';
import { isValidEmail } from '../utils/simple_email_validation';

class StripeCheckout extends React.Component {
  static propTypes = {
    clientSecret: PropTypes.string.isRequired,
    working: PropTypes.bool.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    paymentAmountDisplay: PropTypes.string.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      paymentErrorMessage: null,
      receiptEmail: '',
    };
  }

  handleSubmit = (stripe, elements) => {
    const { receiptEmail } = this.state;

    return (async () => {

      if (!isValidEmail(receiptEmail)) {
        this.setState({
          paymentErrorMessage: this.props.getTextFor('validations.email'),
        });
        return;
      }

      this.setState({
        paymentErrorMessage: null,
      });
      this.props.startWorking();
      const result = await stripe.confirmCardPayment(this.props.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        receipt_email: receiptEmail,
      });

      if (result.error) {
        this.setState({
          paymentErrorMessage: result.error.message,
        });
        this.props.stopWorking();
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          this.setState({
            paymentErrorMessage: null,
          });
        }
      }
    });
  }

  renderProcessingMessage = () => {
    if (this.props.working) {
      return (
        <React.Fragment>
          {this.props.getTextFor('payments.processing-payment')}
        </React.Fragment>
      );
    }
    return '';
  }

  renderErrorMessage = () => {
    if (this.state.paymentErrorMessage) {
      return (
        <div className="page-block__submit--lawpay animate__animated">
          {this.state.paymentErrorMessage}
        </div>
      );
    }
    return '';
  }

  handleReceiptEmail = (e) => {
    this.setState({
      receiptEmail: e.target.value,
    });
  }

  submitButtonText = () => {
    return `${this.props.getTextFor('payments.pay')} ${this.props.paymentAmountDisplay}`;
  }

  submitButtonCssClass = () => {
    if (this.props.working) {
      return 'btn-primary disabled';
    }
    return 'btn-primary';
  }

  render() {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <React.Fragment>
            <fieldset className="stripe-form">
              <div className="stripe-form__topRow">
                <label className="stripe-form__email-label" htmlFor="stripe-email-input">
                  {this.props.getTextFor('save-and-continue.email-address')}
                </label>
                <input id="stripe-email-input" className="stripe-form__email-input" type="text" value={this.state.receiptEmail} placeholder={this.props.getTextFor('save-and-continue.email-address')} onChange={this.handleReceiptEmail} />
              </div>
              <div className="stripe-form__bottomRow">
                <CardElement />
              </div>
            </fieldset>
            <div className="page-block__submit--button-row">
              <button className={this.submitButtonCssClass()} disabled={(!stripe || !elements) || this.props.working} onClick={this.handleSubmit(stripe, elements)}>
                <i className="fas fa-spinner fa-spin" />
                {this.submitButtonText()}
              </button>
            </div>
            {this.renderProcessingMessage()}
            {this.renderErrorMessage()}
          </React.Fragment>
        )}
      </ElementsConsumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  startWorking: (data) => dispatch(startWorking(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeCheckout);
