import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getField } from '../../selectors';

class Acknowledgement extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    this.props.onChange(true);
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  return {
    field,
  };
};

export default connect(mapStateToProps)(Acknowledgement);
