import React from 'react';
import PropTypes from 'prop-types';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { connect } from 'react-redux';
import { loadNewState } from '../actions';
import { updateAssembledDocuments } from '../actions/assembledDocuments';
import { updateWarnings } from '../actions/warnings';
import { updateUi, appendToFastForwardProgress } from '../actions/ui';
import {
  getSessionId,
} from '../selectors';

class ActionCableListener extends React.Component {
  static propTypes = {
    sessionId: PropTypes.string,
    updateAssembledDocuments: PropTypes.func.isRequired,
    updateUi: PropTypes.func.isRequired,
    updateWarnings: PropTypes.func.isRequired,
    appendToFastForwardProgress: PropTypes.func.isRequired,
    loadNewState: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sessionId: null,
  }

  handleBackgroundAssemblyCompletion = (data) => {
    this.props.updateAssembledDocuments(data);
  }

  handleAppSessionTransmission = (data) => {
    this.props.loadNewState(data);
  }

  handleSnapshotChannelTransmission = (data) => {
    const { message } = data;
    this.props.updateUi({
      isMakingSnapshot: false,
      snapshotStatusMessage: message,
    });
  }

  handleFastForwardProgressChannelTransmission = (data) => {
    this.props.appendToFastForwardProgress(data);
  }

  handleValidationTransmission = (data) => {
    this.props.updateWarnings(data);
  }

  renderBackgroundAssemblyListener = () => {
    const { sessionId } = this.props;
    if (!sessionId) return null;

    return (
      <ActionCableConsumer
        channel={{ channel: 'BackgroundAssemblyChannel', app_session_id: this.props.sessionId }}
        onReceived={this.handleBackgroundAssemblyCompletion}
      />
    );
  }

  renderAppSessionListener = () => {
    const { sessionId } = this.props;
    if (!sessionId) return null;

    return (
      <ActionCableConsumer
        channel={{ channel: 'AppSessionChannel', app_session_id: this.props.sessionId }}
        onReceived={this.handleAppSessionTransmission}
      />
    );
  }

  renderSnapshotListener = () => {
    const { sessionId } = this.props;
    if (!sessionId) return null;

    return (
      <ActionCableConsumer
        channel={{ channel: 'SnapshotsChannel', app_session_id: this.props.sessionId }}
        onReceived={this.handleSnapshotChannelTransmission}
      />
    );
  }

  renderFastForwardProgressListener = () => {
    const { sessionId } = this.props;
    if (!sessionId) return null;

    return (
      <ActionCableConsumer
        channel={{ channel: 'FastForwardProgressChannel', app_session_id: this.props.sessionId }}
        onReceived={this.handleFastForwardProgressChannelTransmission}
      />
    );
  }

  renderValidationListener = () => {
    const { sessionId } = this.props;
    if (!sessionId) return null;

    return (
      <ActionCableConsumer
        channel={{ channel: 'ValidationChannel', app_session_id: this.props.sessionId }}
        onReceived={this.handleValidationTransmission}
      />
    );
  }

  render() {
    // We can add further listeners as they become necessary.
    return (
      <React.Fragment>
        {this.renderBackgroundAssemblyListener()}
        {this.renderAppSessionListener()}
        {this.renderSnapshotListener()}
        {this.renderFastForwardProgressListener()}
        {this.renderValidationListener()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // If these are not found, an empty map is returned. Otherwise they're strings.

  let sessionId = getSessionId(state);
  if (sessionId.size === 0) sessionId = null;

  return {
    sessionId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateAssembledDocuments: (data) => dispatch(updateAssembledDocuments(data)),
  loadNewState: (data) => dispatch(loadNewState(data)),
  updateUi: (data) => dispatch(updateUi(data)),
  updateWarnings: (data) => dispatch(updateWarnings(data)),
  appendToFastForwardProgress: (data) => dispatch(appendToFastForwardProgress(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionCableListener);
