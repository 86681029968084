import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
  getActivated,
  makeGetTextFor,
} from '../../selectors';
import MarkdownRenderer from '../MarkdownRenderer';

class ErrorBlock extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    activated: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  renderLiveMode = () => {
    return (
      <div className="page-block animate__animated" role="form">
        {this.props.getTextFor('generic-error')}
      </div>
    );
  }

  renderTestMode = () => {
    const errors = this.props.block.get('errors');
    return (
      <div className="page-block animate__animated" role="form">
        <MarkdownRenderer source={errors.join('\n')} />
      </div>
    );
  }

  render() {
    if (this.props.activated) {
      return this.renderLiveMode();
    }
    return this.renderTestMode();
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  const activated = getActivated(state);
  return {
    block,
    activated,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(ErrorBlock);
