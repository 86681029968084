import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSession,
  getBlockId,
  getSubmissibleOnscreenVariables,
  getWarnings,
  getIsWorking,
  makeGetTextFor,
  getPrerenderMode,
} from '../../selectors';
import { updateWarnings } from '../../actions/warnings';
import { startWorking, stopWorking } from '../../actions/ui';
import { updateSessionRequest } from '../../api';
import { loadNewState } from '../../actions';
import transformFileUploadVariables from '../../utils/file_upload_variable_transformer';

class Submit extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
    loadNewState: PropTypes.func.isRequired,
    submissibleOnscreenVariables: PropTypes.object.isRequired,
    updateWarnings: PropTypes.func.isRequired,
    warnings: PropTypes.object.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    working: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  componentDidMount() {
    document.addEventListener('systemd.submit', this.handleSubmitEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('systemd.submit', this.handleSubmitEvent);
  }

  handleSubmitEvent = (e) => {
    e.preventDefault();
    this.handleSubmit();
  }

  addAppSessionIdToUrl = () => {
    // after successfully submitting a block, we're going to add the session id to the url if it's not already there
    // we're doing this so in case the app was prerendered, refreshing the page will request the existing session instead of the compiled app landing page
    // this avoids the weird flash where you see the prerendered html before the session is retrieved
    if (window && window.history && window.history.replaceState && window.location) {
      const url = window.location.href;
      const sessionId = this.props.session.get('id');
      if (!url.endsWith(sessionId)) {
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}/${sessionId}`,
        );
      }
    }
  }

  handleSubmit = () => {
    const { getTextFor } = this.props;
    this.props.startWorking();

    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
      this.addAppSessionIdToUrl();
    };
    const failure = (data) => {
      if (data.errors) {
        this.props.updateWarnings(data.errors);
      } else {
        alert(data.message);
      }
      this.props.stopWorking();
    };

    const sessionId = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const blockId = this.props.blockId;
    const updateType = 'submission';
    const variables = this.props.submissibleOnscreenVariables.toJS();

    // File upload fields need to first be uploaded to S3 asyncronously. Thus, transformFileUploadVariables
    // does so and returns a Promise which contains the new variable values for these file upload fields.
    // If any errors were encountered, it also contains the warnings object we use to update onscreen warnings with.

    transformFileUploadVariables(variables, getTextFor).then((promiseValues) => {
      const { transformedVariables, uploadValidationsWarningObject } = promiseValues;

      if (Object.keys(uploadValidationsWarningObject).length > 0) {
        this.props.updateWarnings(uploadValidationsWarningObject);
        this.props.stopWorking();
      } else {
        updateSessionRequest(sessionId, sessionKey, updateType, transformedVariables, blockId, null, success, failure);
      }
    });
  }

  renderRequirednessWarning = () => {
    if (this.props.warnings.size > 0) {
      return (
        <div className="page-block__submit--warning animate__animated">
          {this.props.getTextFor('validations.required-warning')}
        </div>
      );
    }

    return null;
  }

  submitButtonCssClass = () => {
    if (this.props.working || this.props.prerenderMode) {
      return 'btn-primary disabled';
    }
    return 'btn-primary';
  }

  render() {
    return (
      <div className="page-block__submit">
        {this.renderRequirednessWarning()}
        <div className="page-block__submit--button-row">
          <button className={this.submitButtonCssClass()} onClick={this.handleSubmit} disabled={this.props.working || this.props.prerenderMode}>
            {this.props.working ? <i className="fas fa-spinner fa-spin" /> : ''}
            {this.props.getTextFor('continue')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const blockId = getBlockId(state);

  return {
    blockId,
    session: getSession(state),
    submissibleOnscreenVariables: getSubmissibleOnscreenVariables(state),
    warnings: getWarnings(state),
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
    prerenderMode: getPrerenderMode(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
  updateWarnings: (data) => dispatch(updateWarnings(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
  startWorking: (data) => dispatch(startWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
