import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
  getMarkdownAttachments,
  getWordAttachments,
  getPdfAttachments,
  getFileAttachments,
  getSaveAndContinueEnabled,
  makeGetTextFor,
} from '../selectors';
import Question from './blocks/Question';
import QuestionInline from './blocks/QuestionInline';
import Signature from './blocks/Signature';
import Final from './blocks/Final';
import Info from './blocks/Info';
import BackButton from './blocks/BackButton';
import LoopReview from './blocks/LoopReview';
import BlockMarkdownAttachment from './BlockMarkdownAttachment';
import BlockWordAttachment from './BlockWordAttachment';
import BlockPdfAttachment from './BlockPdfAttachment';
import BlockFileAttachment from './BlockFileAttachment';
import Stripe from './blocks/Stripe';
import Lawpay from './blocks/Lawpay';
import Redirect from './blocks/Redirect';
import Hellosign from './blocks/Hellosign';
import ErrorBlock from './blocks/ErrorBlock';
import SaveAndContinueButton from './SaveAndContinueButton';

class Block extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    markdownAttachments: PropTypes.object.isRequired,
    wordAttachments: PropTypes.object.isRequired,
    pdfAttachments: PropTypes.object.isRequired,
    fileAttachments: PropTypes.object.isRequired,
    getTextFor: PropTypes.func.isRequired,
    saveAndContinueEnabled: PropTypes.bool.isRequired,
  }

  componentDidUpdate(prevProps) {
    const oldBlockId = prevProps.block.get('id');
    const newBlockId = this.props.block.get('id');
    if (oldBlockId !== newBlockId) {
      window.scrollTo(0, 0);
    }
  }

  renderBlockHeader = () => {
    return (
      <div className="block-header">
        <div className="block-header__leftCol">
          {this.renderBackButton()}
        </div>
        <div className="block-header__rightCol">
          {this.renderSaveAndContinueButton()}
        </div>
      </div>
    );
  }

  renderSaveAndContinueButton = () => {
    const saveAndContinueElement = this.props.saveAndContinueEnabled ? (
      <SaveAndContinueButton />
    ) : null;

    // We can extend this guard as we add more footer clauses, e.g. `if (!footerObject1 && !footerObject2 && ...) return null`
    if (!saveAndContinueElement) return null;

    return (
      <React.Fragment>
        {saveAndContinueElement}
      </React.Fragment>
    );
  }

  renderBackButton = () => {
    const { block } = this.props;
    if (block.get('back_button')) {
      return (<BackButton />);
    }
    return '';
  }

  renderBlockInterior = () => {
    const { block } = this.props;
    const id = block.get('id');
    const errors = block.get('errors');

    if (errors.size > 0) {
      return (<ErrorBlock id={id} />);
    }

    switch (block.get('type')) {
      case 'question':
        return this.props.block.get('displayEmbeddedVariables') ? (<QuestionInline id={id} />) : (<Question id={id} />);
      case 'signature':
        return (<Signature id={id} />);
      case 'final':
        return (<Final id={id} />);
      case 'info':
        return (<Info id={id} />);
      case 'stripe':
        return (<Stripe id={id} />);
      case 'lawpay':
        return (<Lawpay id={id} />);
      case 'loop_review':
        return (<LoopReview id={id} />);
      case 'hellosign':
        return (<Hellosign id={id} />);
      case 'redirect':
        return (<Redirect url={this.props.block.get('redirect_url')} text={this.props.getTextFor('redirecting')} />);
      case 'error':
        return (<ErrorBlock id={id} />);
      default:
        return '';
    }
  }

  renderOrderedAttachments = () => {
    const attachmentsList = [
      ...this.props.markdownAttachments.valueSeq(),
      ...this.props.wordAttachments.valueSeq(),
      ...this.props.pdfAttachments.valueSeq(),
      ...this.props.fileAttachments.valueSeq(),
    ];
    const withUndefinedOrder = attachmentsList.filter((attachment) => {
      return Number.isNaN(parseFloat(attachment.get('runtimeOrder'), 10));
    });
    const withDefinedOrder = attachmentsList.filter((attachment) => {
      return !(Number.isNaN(parseFloat(attachment.get('runtimeOrder'), 10)));
    });
    const orderedAttachments = withDefinedOrder.sort((a, b) => {
      return a.get('runtimeOrder') - b.get('runtimeOrder');
    });
    const finallyOrderedAttachments = [
      ...orderedAttachments,
      ...withUndefinedOrder,
    ];
    return finallyOrderedAttachments.map((attachment) => {
      const kind = attachment.get('kind');
      const attachmentId = attachment.get('id');
      if (kind === 'pdf') {
        return (
          <BlockPdfAttachment
            attachmentId={attachmentId}
            key={attachmentId}
          />
        );
      }
      if (kind === 'word') {
        return (
          <BlockWordAttachment
            attachmentId={attachmentId}
            key={attachmentId}
          />
        );
      }
      if (kind === 'markdown') {
        return (
          <BlockMarkdownAttachment
            attachmentId={attachmentId}
            key={attachmentId}
          />
        );
      }
      if (kind === 'file') {
        return (
          <BlockFileAttachment
            attachmentId={attachmentId}
            key={attachmentId}
          />
        );
      }
      return '';
    });
  }

  renderAttachmentList = () => {
    if (this.props.block.get('type') === 'hellosign') {
      // don't want to display the 'intermediate' hellosign-tagged docs to the user (they get sent to hellosign)
      return '';
    }
    return (
      <div className="attachment-list">
        {this.renderOrderedAttachments()}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderBlockHeader()}
        <div className="page-block-wrapper" role="main">
          {this.renderBlockInterior()}
          {this.renderAttachmentList()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  const markdownAttachments = getMarkdownAttachments(state);
  const wordAttachments = getWordAttachments(state);
  const pdfAttachments = getPdfAttachments(state);
  const fileAttachments = getFileAttachments(state);
  const saveAndContinueEnabled = getSaveAndContinueEnabled(state);

  return {
    block,
    markdownAttachments,
    wordAttachments,
    pdfAttachments,
    fileAttachments,
    saveAndContinueEnabled,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(Block);
