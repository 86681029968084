import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
} from '../../selectors';
import Field from '../Field';
import Submit from './Submit';
import MarkdownRenderer from '../MarkdownRenderer';

class QuestionInline extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
  }

  renderSubquestion = () => {
    return (
      <MarkdownRenderer source={this.props.block.get('subquestion')} inline />
    );
  }

  renderUnembeddedVariables = () => {
    const fields = this.props.block.get('unembeddedVariableNames').map((variableName) => {
      return (
        <Field variableName={variableName} key={variableName} />
      );
    });

    return (
      <React.Fragment>
        {fields}
      </React.Fragment>
    );
  }

  renderSubmit = () => {
    return (
      <Submit />
    );
  }

  render() {
    return (
      <div className="page-block page-block__question animate__animated" role="form">
        <div className="page-block__markdownRenderer">
          {this.renderSubquestion()}
        </div>
        {this.renderUnembeddedVariables()}
        {this.renderSubmit()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);

  return {
    block,
  };
};

export default connect(mapStateToProps)(QuestionInline);
