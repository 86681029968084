import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkdownRenderer from '../MarkdownRenderer';
import { getField } from '../../selectors';

class CollapsibleField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    field: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      showingCollapse: false,
    };
  }

  renderCollapseBody = () => {
    return (
      <div className={`collapse-body${this.state.showingCollapse ? ' collapse-body--show' : ''}`}>
        <MarkdownRenderer source={this.props.field.get('collapseBody')} />
      </div>
    );
  }

  toggleCollapseShown = () => {
    this.setState((currState) => ({ showingCollapse: !currState.showingCollapse }));
  }

  render() {
    return (
      <div className="page-block__field-wrapper collapse-wrapper">
        <div className={`collapse-trigger${this.state.showingCollapse ? ' collapse-trigger--clicked' : ''}`} onClick={this.toggleCollapseShown}>
          <div className="collapse-trigger__icon-col">
            <i className="fas fa-caret-right"></i>
          </div>
          <MarkdownRenderer source={this.props.field.get('label')} />
        </div>
        {this.renderCollapseBody()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(CollapsibleField);
