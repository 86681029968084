import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getField,
} from '../../selectors';
import NativeTableSelector from './NativeTableSelector';
import ClioTableSelector from './ClioTableSelector';
import GoogleTableSelector from './GoogleTableSelector';
import CldbTableSelector from './CldbTableSelector';

class DataSourceSelector extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  render() {
    switch (this.props.field.get('tableType')) {
      case 'table':
        return (<NativeTableSelector {...this.props} />);
      case 'clio_table':
        return (<ClioTableSelector {...this.props} />);
      case 'google_table':
        return (<GoogleTableSelector {...this.props} />);
      case 'cldb_table':
        return (<CldbTableSelector {...this.props} />);
      default:
        return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(DataSourceSelector);
