export const UPDATE_UI = 'UPDATE_UI';
export const APPEND_TO_FAST_FORWARD_PROGRESS = 'APPEND_TO_FAST_FORWARD_PROGRESS';
export const START_WORKING = 'START_WORKING';
export const STOP_WORKING = 'STOP_WORKING';
export const UPDATE_SAVE_AND_CONTINUE_MODAL_SHOWING = 'UPDATE_SAVE_AND_CONTINUE_MODAL_SHOWING';

export const updateUi = (data) => ({
  type: UPDATE_UI,
  data,
});

export const appendToFastForwardProgress = (data) => ({
  type: APPEND_TO_FAST_FORWARD_PROGRESS,
  data,
});

export const startWorking = () => ({
  type: START_WORKING,
});

export const stopWorking = () => ({
  type: STOP_WORKING,
});

export const updateSaveAndContinueModalShowing = (data) => ({
  type: UPDATE_SAVE_AND_CONTINUE_MODAL_SHOWING,
  data,
});
