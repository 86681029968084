import { normalize, schema } from 'normalizr';
import { fromJS } from 'immutable';
import { emptyMap } from '../utils/emptiness';

const defaultUi = {
  working: false,
  saveAndContinueModalShowing: false,
  isMakingSnapshot: false,
  snapshotStatusMessage: '',
};

const normalizeAppSession = (oldState, newState) => {
  const choiceSchema = new schema.Entity('choices');
  const fieldSchema = new schema.Entity('fields', {
    choices: [choiceSchema],
  });
  const markdownAttachmentsSchema = new schema.Entity('markdownAttachments');
  const fileAttachmentsSchema = new schema.Entity('fileAttachments');
  const wordAttachmentsSchema = new schema.Entity('wordAttachments');
  const pdfAttachmentsSchema = new schema.Entity('pdfAttachments');
  const blockSchema = new schema.Entity('block', {
    fields: [fieldSchema],
    markdownAttachments: [markdownAttachmentsSchema],
    wordAttachments: [wordAttachmentsSchema],
    pdfAttachments: [pdfAttachmentsSchema],
    fileAttachments: [fileAttachmentsSchema],
  });
  const sessionSchema = new schema.Entity('session', {
    block: blockSchema,
  });

  const incomingState = newState || {};
  const normalizedData = normalize(incomingState, sessionSchema).entities;
  normalizedData.sessionId = newState.id;
  normalizedData.blockId = newState.block.id || '0';
  normalizedData.onscreen = {};
  normalizedData.warnings = {};
  normalizedData.ui = defaultUi;
  normalizedData.ui.hasSessionWebsocketConnection = ((oldState || emptyMap).get('ui') || emptyMap).get('hasSessionWebsocketConnection');
  normalizedData.ui.hasAssemblyWebsocketConnection = ((oldState || emptyMap).get('ui') || emptyMap).get('hasAssemblyWebsocketConnection');
  normalizedData.variables = newState.variables;
  normalizedData.presignedS3Urls = newState.presignedS3Urls;
  normalizedData.assembledDocuments = newState.assembledDocuments;
  normalizedData.styles = newState.styles;
  normalizedData.activated = newState.activated;
  normalizedData.selectedCurrency = newState.selected_currency;
  normalizedData.selectedLanguage = newState.selected_language;
  normalizedData.translations = JSON.parse(newState.translations);
  normalizedData.saveAndContinueEnabled = newState.save_and_continue_enabled;
  normalizedData.clientPortalEndpoint = newState.client_portal_endpoint;
  normalizedData.includeClAttribution = newState.include_cl_attribution;
  normalizedData.loopVariableInProgress = newState.loop_variable_in_progress;
  normalizedData.fieldIdsByVariableName = {};

  Object.values(normalizedData.fields || {}).forEach((field) => {
    normalizedData.onscreen[field.immutableVariableName] = field.default;
    normalizedData.fieldIdsByVariableName[field.immutableVariableName] = field.id;
  });

  return fromJS(normalizedData);
};

export default normalizeAppSession;
