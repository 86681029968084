import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
  getLoopValue,
  getSession,
  getIsWorking,
  makeGetTextFor,
} from '../../selectors';
import MarkdownRenderer from '../MarkdownRenderer';
import LoopMember from './LoopMember';
import { updateSessionRequest } from '../../api';
import { loadNewState } from '../../actions';
import { startWorking, stopWorking } from '../../actions/ui';

class LoopReview extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    loadNewState: PropTypes.func.isRequired,
    loopValue: PropTypes.object.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    working: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  handleRepeat = () => {
    this.props.startWorking();

    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };
    const failure = (data) => {
      this.props.stopWorking();
      alert(data.message);
    };

    const id = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const updateType = 'repeat_loop';
    updateSessionRequest(id, sessionKey, updateType, null, null, null, success, failure);
  }

  handleTerminate = () => {
    this.props.startWorking();

    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };
    const failure = (data) => {
      this.props.stopWorking();
      alert(data.message);
    };

    const id = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const updateType = 'terminate_loop';
    updateSessionRequest(id, sessionKey, updateType, null, null, null, success, failure);
  }

  renderSubquestion = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.block.get('subquestion')} />
      </React.Fragment>
    );
  }

  renderMembers = () => {
    return this.props.loopValue.map((member, index) => {
      return this.renderMember(member, index);
    });
  }

  renderMember = (member, index) => {
    return (
      <LoopMember
        member={member}
        index={index}
        key={index}
      />
    );
  }

  renderRepeat = () => {
    return (
      <div className="page-block__submit">
        <div className="page-block__submit--add-to-loop-row">
          <button className="btn-secondary btn-secondary--add-to-loop" onClick={this.handleRepeat} disabled={this.props.working}>
            <i className="fas fa-plus" />
            {this.props.getTextFor('loops.add')}
          </button>
        </div>
      </div>
    );
  }

  submitButtonCssClass = () => {
    if (this.props.working) {
      return 'btn-primary disabled';
    }
    return 'btn-primary';
  }

  renderTerminate = () => {
    return (
      <div className="page-block__submit">
        <div className="page-block__submit--button-row">
          <button className={this.submitButtonCssClass()} onClick={this.handleTerminate} disabled={this.props.working}>
            <i className="fas fa-spinner fa-spin" />
            {this.props.getTextFor('loops.continue')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page-block page-block__question animate__animated" role="form">
        <div className="loop-header">
          {this.renderSubquestion()}
        </div>
        <div className="loop-members">
          {this.renderMembers()}
        </div>
        {this.renderRepeat()}
        {this.renderTerminate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  const loopValue = getLoopValue(state);

  return {
    block,
    loopValue,
    session: getSession(state),
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => { dispatch(loadNewState(data)); },
  stopWorking: (data) => dispatch(stopWorking(data)),
  startWorking: (data) => dispatch(startWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoopReview);
