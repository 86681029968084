import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getHideNavbar,
  getFastForwarding,
  getCurrentWaypoint,
  getSessionId,
} from '../selectors';
import FastForwarding from './FastForwarding';
import Block from './Block';
import Navbar from './Navbar';
import TestModeBadge from './TestModeBadge';
import SandboxModeBadge from './SandboxModeBadge';
import DeveloperModeBadge from './DeveloperModeBadge';
import ProgressBar from './ProgressBar';
import WaypointSidebar from './WaypointSidebar';

import { loadSessionRequest, newSessionRequest } from '../api';
import { loadNewState } from '../actions';
import { appPath } from '../utils';

class AppSession extends React.Component {
  static propTypes = {
    hideNavbar: PropTypes.bool.isRequired,
    fastForwarding: PropTypes.bool,
    currentWaypoint: PropTypes.string,
    sessionId: PropTypes.string.isRequired,
    loadNewState: PropTypes.func.isRequired,
    _accessKey: PropTypes.string,
    appId: PropTypes.string,
    urlParams: PropTypes.string,
  }

  static defaultProps = {
    fastForwarding: false,
    currentWaypoint: null,
    _accessKey: null,
    urlParams: '',
    appId: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      showingDebugMessage: false,
    };
  }

  componentDidMount = () => {
    // if we have the #rendered node and we are NOT tweezing, then we need to get a real session
    if (document.querySelector('#rendered')) {
      const mountNodeId = 'data-node';
      const mountNode = document.getElementById(mountNodeId);
      const tweezing = mountNode.getAttribute('data-tweezing');
      if (tweezing !== 'true') {
        // this is a human user that got a prerendered 'ghost' session
        // we need to request a new session now
        this.performNewSessionRequest();
      }
    }
  }

  performNewSessionRequest = () => {
    const success = (object) => {
      this.props.loadNewState(object);
      if (window.sessionStorage) {
        window.sessionStorage.setItem('app_session_id', object.id);
        window.sessionStorage.setItem('app_path', appPath());
      }
    };
    const failure = (message) => {
      alert(message);
    };

    const {
      appId,
      _accessKey,
      urlParams,
    } = this.props;

    let sessionStorageId;
    if (window.sessionStorage) {
      sessionStorageId = window.sessionStorage.getItem('app_session_id');
    } else {
      sessionStorageId = null;
    }

    const lastAppPath = window.sessionStorage.getItem('app_path');
    const currentAppPath = appPath();

    if (sessionStorageId && (!lastAppPath || lastAppPath === currentAppPath)) {
      loadSessionRequest(sessionStorageId, success, failure);
    } else {
      const parsedParams = JSON.parse(urlParams);
      newSessionRequest(appId, _accessKey, parsedParams, success, failure);
    }
  }

  renderNavbar = () => {
    if (!this.props.hideNavbar) {
      return (
        <Navbar />
      );
    }
    return '';
  }

  toggleDebugMessage = () => {
    const { showingDebugMessage } = this.state;
    this.setState({
      showingDebugMessage: !showingDebugMessage,
    });
  }

  renderDebugDisclosure = () => {
    return (
      <div className="debug-element">
        {this.renderDebugMessage()}
        <i className="far fa-question-circle debug-element__icon" onClick={this.toggleDebugMessage} />
      </div>
    );
  }

  renderDebugMessage = () => {
    if (this.state.showingDebugMessage) {
      return (
        <div className="debug-element__message">
          To report a problem, provide the following session ID to the administrator of this website: {this.props.sessionId}
        </div>
      );
    }
    return '';
  }

  render() {
    const { fastForwarding, currentWaypoint } = this.props;
    if (fastForwarding) {
      return (
        <FastForwarding />
      );
    }

    let wrapperClassName = 'app-body-wrapper';
    if (currentWaypoint) wrapperClassName += ' app-body-wrapper--disabled';

    return (
      <div className={wrapperClassName}>
        <span className="v4" />
        {this.renderNavbar()}
        <WaypointSidebar />
        <div className="app-body">
          <ProgressBar />
          <Block />
        </div>
        <div className="test-mode-wrapper">
          <TestModeBadge />
          <SandboxModeBadge />
          <DeveloperModeBadge />
        </div>
        {this.renderDebugDisclosure()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const hideNavbar = getHideNavbar(state);
  const fastForwarding = getFastForwarding(state);
  const currentWaypoint = getCurrentWaypoint(state);
  const sessionId = getSessionId(state);

  return {
    hideNavbar,
    fastForwarding,
    currentWaypoint,
    sessionId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSession);
