import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

class MaskedInputWrapper extends React.Component {
  // wrapper for MaskedInput

  static propTypes = {
    mask: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    placeholder: '',
  }

  render() {
    return (
      <React.Fragment>
        <MaskedInput
          type="text"
          mask={this.props.mask}
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          id={this.props.id}
        />
      </React.Fragment>
    );
  }
}

export default MaskedInputWrapper;
