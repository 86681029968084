import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalWrapper from './ModalWrapper';
import { makeGetTextFor } from '../selectors';

class ConfirmationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
    getTextFor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  }

  renderConfirmButton = () => {
    return (
      <button className="confirmation-modal__confirm-button btn-warning" onClick={this.props.onConfirm}>
        {this.props.getTextFor('confirm')}
      </button>
    );
  }

  renderCancelButton = () => {
    return (
      <button className="confirmation-modal__cancel-button btn-secondary" onClick={this.props.onCancel}>
        {this.props.getTextFor('cancel')}
      </button>
    );
  }

  render() {
    return (
      <ModalWrapper
        isOpen={this.props.isOpen}
        shouldCloseOnEsc
      >
        <div className="confirmation-modal confirmation-modal__brooklyn animate__animated">
          {this.props.children}
          <div className="confirmation-modal__button-row">
            <div className="confirmation-modal__button-row--left-col">
              {this.renderConfirmButton()}
            </div>
            <div className="confirmation-modal__button-row--right-col">
              {this.renderCancelButton()}
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(ConfirmationModal);
