import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HelloSign from 'hellosign-embedded';
import {
  getBlock,
  getSession,
  getHellosignClientId,
  getFirstAssembledDocument,
  getIsWorking,
  makeGetTextFor,
} from '../../selectors';
import { updateSessionRequest } from '../../api';
import { loadNewState } from '../../actions';
import MarkdownRenderer from '../MarkdownRenderer';
import { startWorking, stopWorking } from '../../actions/ui';

class Hellosign extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    working: PropTypes.bool.isRequired,
    session: PropTypes.object.isRequired,
    sessionId: PropTypes.string.isRequired,
    assembledDocument: PropTypes.object.isRequired,
    hellosignClientId: PropTypes.string.isRequired,
    loadNewState: PropTypes.func.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  initialLifecycleStage = (props) => {
    const assembledDocument = props.assembledDocument;
    const assembledDocumentStatus = assembledDocument.get('status');
    const assembledDocumentMode = assembledDocument.get('hellosign_mode');
    if (!assembledDocumentStatus || assembledDocumentStatus === 'processing') {
      return 'waiting_for_initial_document_assembly';
    }
    if (assembledDocumentStatus === 'completed' && assembledDocumentMode === 'onscreen') {
      return 'waiting_for_signed_document';
    }
    if (assembledDocumentStatus === 'signed') {
      return 'signed';
    }
    if (assembledDocumentStatus === 'error') {
      return 'error';
    }
    return '';
  }

  constructor(props) {
    super(props);

    this.state = {
      hellosignClient: new HelloSign({ clientId: this.props.hellosignClientId }),
      lifecycleStage: this.initialLifecycleStage(props),
      hellosignIframeHasBeenOpened: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { hellosignIframeHasBeenOpened } = this.state;
    const previousAssembledDocumentStatus = prevProps.assembledDocument.get('status');
    const currentAssembledDocumentStatus = props.assembledDocument.get('status');
    if ((currentAssembledDocumentStatus === 'completed') && !hellosignIframeHasBeenOpened) {
      this.openHellosignIframe();
    }
    if ((currentAssembledDocumentStatus === 'signed') && (currentAssembledDocumentStatus !== previousAssembledDocumentStatus)) {
      this.setState({ lifecycleStage: 'advancing' }); // eslint-disable-line react/no-did-update-set-state
      this.advanceBlock();
    }
  }

  advanceBlock = () => {
    this.props.startWorking();
    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };
    const failure = (data) => {
      this.props.stopWorking();
      alert(data.message);
    };

    const sessionId = this.props.sessionId;
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const blockId = this.props.block.get('id');
    const updateType = 'hellosign';

    updateSessionRequest(sessionId, sessionKey, updateType, null, blockId, null, success, failure);
  }

  openHellosignIframe = () => {
    const { assembledDocument } = this.props;
    const { hellosignClient } = this.state;

    if (!assembledDocument.get('status')) {
      return '';
    }

    const hellosignIframeUrl = assembledDocument.get('hellosignIframeUrl');
    if (!hellosignIframeUrl) {
      return '';
    }

    if (!hellosignClient) {
      return '';
    }

    // if we are EMAIL rather than ONSCREEN, need to `client.on` to make sure that the block advances after signing
    const mode = assembledDocument.get('hellosign_mode');
    if (mode === 'email') {
      hellosignClient.on(HelloSign.events.SEND, (_) => {
        this.advanceBlock();
      });
    }

    hellosignClient.open(hellosignIframeUrl, {
      allowCancel: false,
      skipDomainVerification: true,
    });

    this.setState({
      hellosignIframeHasBeenOpened: true,
    });
    return '';
  }

  renderSubquestion = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.block.get('subquestion')} />
      </React.Fragment>
    );
  }

  renderRetrieving = () => {
    return (
      <div className="hellosign-loading">
        <span>
          {this.props.getTextFor('hellosign.retrieving')}
        </span>
        <span>
          <i className="fas fa-spinner fa-spin" />
        </span>
      </div>
    );
  }

  continueButtonCssClass = () => {
    if (this.props.working) {
      return 'btn-primary disabled';
    }
    return 'btn-primary';
  }

  renderContinueButton() {
    return (
      <div className="page-block__submit">
        <div className="page-block__submit--button-row">
          <button className={this.continueButtonCssClass()} onClick={this.advanceBlock} disabled={this.props.working}>
            <i className="fas fa-spinner fa-spin" />
            {this.props.getTextFor('hellosign.continue')}
          </button>
        </div>
      </div>
    );
  }

  renderAleadySigned = () => {
    return (
      <div>
        {this.props.getTextFor('hellosign.already-signed')}
        {this.renderContinueButton()}
      </div>
    );
  }

  renderPreparingForSignature = () => {
    return (
      <div className="hellosign-loading">
        <span>
          {this.props.getTextFor('hellosign.preparing')}
        </span>
        <span>
          <i className="fas fa-spinner fa-spin" />
        </span>
      </div>
    );
  }

  renderError = () => {
    return (
      <div>
        {this.props.getTextFor('hellosign.error')}: {this.props.assembledDocument.get('hellosign_error')}
      </div>
    );
  }

  renderLifecycleMessage = () => {
    const { lifecycleStage } = this.state;
    const { assembledDocument } = this.props;
    const assembledDocumentStatus = assembledDocument.get('status');
    const mode = assembledDocument.get('hellosign_mode');
    if (lifecycleStage === 'error' || assembledDocumentStatus === 'error') {
      return this.renderError();
    }
    if (lifecycleStage === 'advancing') {
      return '';
    }
    if (mode === 'onscreen' && (lifecycleStage === 'waiting_for_signed_document' || assembledDocumentStatus === 'completed')) {
      return this.renderRetrieving();
    }
    if (lifecycleStage === 'signed' || assembledDocumentStatus === 'signed' || assembledDocumentStatus === 'out_for_signature') {
      return this.renderAleadySigned();
    }
    if (lifecycleStage === 'waiting_for_initial_document_assembly') {
      return this.renderPreparingForSignature();
    }
    return '';
  }

  render() {
    return (
      <div className="page-block animate__animated">
        {this.renderSubquestion()}
        {this.renderLifecycleMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  const session = getSession(state);
  const sessionId = session.get('id');
  const assembledDocument = getFirstAssembledDocument(state);
  const hellosignClientId = getHellosignClientId(state);
  const working = getIsWorking(state);
  return {
    block,
    session,
    sessionId,
    assembledDocument,
    hellosignClientId,
    working,
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
  startWorking: (data) => dispatch(startWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hellosign);
