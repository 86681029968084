import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSessionId,
  getSessionKey,
  getDisplayMap,
  getUi,
} from '../selectors';
import { variableValueRequest } from '../api';
import { updateUi } from '../actions/ui';
import ReactSelectWrapper from './ReactSelectWrapper';
import MarkdownRenderer from './MarkdownRenderer';

class CrystalBall extends React.Component {
  static propTypes = {
    displayMap: PropTypes.object.isRequired,
    sessionKey: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
    updateUi: PropTypes.func.isRequired,
    selectedVariable: PropTypes.string,
    variableValue: PropTypes.string,
  }

  static defaultProps = {
    selectedVariable: null,
    variableValue: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      working: false,
    };
  }

  startWorking = () => {
    this.setState({ working: true });
  }

  stopWorking = () => {
    this.setState({ working: false });
  }

  options = () => {
    const mapped = this.props.displayMap.map((val, key) => {
      // key is immutable variable name, value is user friendly name
      return { label: val, value: key };
    });
    return Object.values(mapped.toJS());
  }

  getSelectedValue = () => {
    const options = this.options();
    return options.find((option) => {
      return option.value === this.props.selectedVariable;
    });
  }

  handleChange = (e) => {
    this.startWorking();
    const { value } = e;
    this.props.updateUi({ crystalBallVariable: value });
    const success = (object) => {
      this.props.updateUi({ crystalBallValue: object.value });
      this.stopWorking();
    };
    const failure = (message) => {
      this.stopWorking();
      alert(message);
    };
    variableValueRequest(this.props.sessionId, this.props.sessionKey, value, success, failure);
  }

  renderSelect = () => {
    return (
      <ReactSelectWrapper
        options={this.options()}
        selectedValue={this.getSelectedValue()}
        onChange={this.handleChange}
        isClearable={false}
      />
    );
  }

  renderUndefined = () => {
    return (
      <div className="crystall-ball__undefined">
        Undefined
      </div>
    );
  }

  renderVariableValue = () => {
    if (!this.props.selectedVariable) {
      return '';
    }

    if (this.state.working) {
      return '';
    }

    const { variableValue } = this.props;
    if (variableValue === null || variableValue === undefined) {
      return this.renderUndefined();
    }

    return (
      <div className="crystall-ball__var-value">
        <MarkdownRenderer source={variableValue} />
      </div>
    );
  }

  render() {
    return (
      <div className="crystall-ball">
        <label>
          Check variable value:
        </label>
        {this.renderSelect()}
        {this.renderVariableValue()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    displayMap: getDisplayMap(state),
    sessionId: getSessionId(state),
    sessionKey: getSessionKey(state),
    selectedVariable: getUi(state).get('crystalBallVariable'),
    variableValue: getUi(state).get('crystalBallValue'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUi: (data) => dispatch(updateUi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrystalBall);
