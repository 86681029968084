/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getField,
  makeOnscreenValueForVariable,
  shouldDisplayField,
  getWarnings,
  getActivated,
  makeGetTextFor,
  getFieldIdForVariableName,
  getPrerenderMode,
} from '../selectors';
import MarkdownRenderer from './MarkdownRenderer';
import ShortText from './fields/ShortText';
import LongText from './fields/LongText';
import YesNoRadio from './fields/YesNoRadio';
import Integer from './fields/Integer';
import CurrencyField from './fields/CurrencyField';
import PasswordField from './fields/PasswordField';
import Radio from './fields/Radio';
import NumberField from './fields/NumberField';
import Note from './fields/Note';
import JavascriptField from './fields/JavascriptField';
import ButtonField from './fields/ButtonField';
import CollapsibleField from './fields/CollapsibleField';
import Acknowledgement from './fields/Acknowledgement';
import DateField from './fields/DateField';
import TimeField from './fields/TimeField';
import RangeField from './fields/RangeField';
import EmailField from './fields/EmailField';
import Checkboxes from './fields/Checkboxes';
import ListSelector from './fields/ListSelector';
import ComboboxField from './fields/ComboboxField';
import DropdownField from './fields/DropdownField';
import DataSourceSelector from './fields/DataSourceSelector';
import FileUploadField from './fields/FileUploadField';
import ImageUploadField from './fields/ImageUploadField';
import ImageElement from './fields/ImageElement';
import FieldValidationWarning from './fields/FieldValidationWarning';
import FieldValidationWarningInline from './fields/FieldValidationWarningInline';
import { updateOnscreen } from '../actions/onscreen';
import {
  idempotentStringAsBool,
  ENTER,
} from '../utils';

class Field extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    variableName: PropTypes.string,
    field: PropTypes.object.isRequired,
    updateOnscreen: PropTypes.func.isRequired,
    onscreenValueForVariable: PropTypes.func.isRequired,
    shouldDisplay: PropTypes.bool,
    activated: PropTypes.bool,
    thisFieldWarning: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    getTextFor: PropTypes.func.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    shouldDisplay: false,
    activated: false,
    thisFieldWarning: null,
    variableName: null,
  }

  onChange = (value) => {
    const immutableVariableName = this.props.field.get('immutableVariableName');
    this.props.updateOnscreen({ [immutableVariableName]: value });
  }

  listenForSubmit = (e) => {
    if (e.keyCode === ENTER) {
      const submitEvent = new Event('systemd.submit');
      document.dispatchEvent(submitEvent);
    }
  }

  renderHiddenField = () => {
    if (this.props.activated) {
      return '';
    }
    return (
      <div className="hidden-field">
        <div className="hidden-field__wrapper">
          <div className="hidden-field__header">
            <i className="fas fa-eye-slash" />
            <p className="hidden-field__label">
              {this.props.getTextFor('hidden-content')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderLabel = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.field.get('label')} />
      </React.Fragment>
    );
  }

  renderRequired = () => {
    if (!this.props.field.get('required')) return null;

    return (
      <React.Fragment>
        *
      </React.Fragment>
    );
  }

  renderWarnings = () => {
    const { thisFieldWarning } = this.props;

    if (thisFieldWarning) {
      if (this.props.field.get('inline')) {
        return (
          <FieldValidationWarningInline warning={thisFieldWarning} fieldId={this.props.id} />
        );
      }

      return (
        <FieldValidationWarning warning={thisFieldWarning} fieldId={this.props.id} />
      );
    }

    return null;
  }

  renderField() {
    if (!this.props.shouldDisplay) {
      return this.renderHiddenField();
    }
    const { field, id, prerenderMode } = this.props;

    const basicProps = {
      id,
      inline: field.get('inline'),
      onChange: this.onChange,
      renderLabel: this.renderLabel,
      renderRequired: this.renderRequired,
      renderWarnings: this.renderWarnings,
      listenForSubmit: this.listenForSubmit,
      prerenderMode,
    };

    switch (field.get('datatype')) {
      case 'text':
        return (
          <ShortText
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'area':
        return (
          <LongText
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'email':
        return (
          <EmailField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'integer':
        return (
          <Integer
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'number':
        return (
          <NumberField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'currency':
        return (
          <CurrencyField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'password':
        return (
          <PasswordField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'list_selector':
        return (
          <ListSelector
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'dropdown':
        return (
          <DropdownField
            {...basicProps}
            required={field.get('required')}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'yesnoradio':
        return (
          <YesNoRadio
            {...basicProps}
            required={field.get('required')}
            value={idempotentStringAsBool(this.props.onscreenValueForVariable(field.get('immutableVariableName')))}
          />
        );
      case 'datetime':
      case 'date':
        return (
          <DateField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'time':
        return (
          <TimeField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'range':
        return (
          <RangeField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName'))}
          />
        );
      case 'acknowledgement':
        return (
          <Acknowledgement
            id={id}
            onChange={this.onChange}
            renderLabel={this.renderLabel}
          />
        );
      case 'radio':
        return (
          <Radio
            {...basicProps}
            required={field.get('required')}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'checkboxes':
        return (
          <Checkboxes
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'combobox':
        return (
          <ComboboxField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'files':
      case 'file':
        return (
          <FileUploadField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'image_upload':
        return (
          <ImageUploadField
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'table':
        return (
          <DataSourceSelector
            {...basicProps}
            value={this.props.onscreenValueForVariable(field.get('immutableVariableName')) || ''}
          />
        );
      case 'note':
      case 'html':
        return (
          <Note
            id={id}
            renderLabel={this.renderLabel}
          />
        );
      case 'javascript':
        return (
          <JavascriptField id={id} />
        );
      case 'button':
        return (
          <ButtonField
            id={id}
          />
        );
      case 'collapse':
        return (
          <CollapsibleField
            id={id}
          />
        );
      case 'image':
        return (
          <ImageElement
            id={id}
            renderLabel={this.renderLabel}
          />
        );
      default:
        return '';
    }
  }

  render() {
    return (
      <React.Fragment key={`${this.props.id}-${this.props.thisFieldWarning}`}>
        {this.renderField()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let field;
  let fieldId;
  if (ownProps.variableName) {
    fieldId = getFieldIdForVariableName(state, ownProps.variableName);
    field = getField(state, fieldId);
  } else {
    fieldId = ownProps.id;
    field = getField(state, fieldId);
  }
  const onscreenValueForVariable = makeOnscreenValueForVariable(state);
  const shouldDisplay = field.get('inline') || shouldDisplayField(state, fieldId);
  const warnings = getWarnings(state);
  const activated = getActivated(state);
  const thisFieldWarning = warnings.get(fieldId);

  return {
    field,
    onscreenValueForVariable,
    shouldDisplay,
    thisFieldWarning,
    activated,
    id: fieldId,
    getTextFor: makeGetTextFor(state),
    prerenderMode: getPrerenderMode(state),
  };
};

const mapDispatchToProps = dispatch => ({
  updateOnscreen: data => dispatch(updateOnscreen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Field);
