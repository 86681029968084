import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getField,
} from '../../selectors';
import { emptyList } from '../../utils/emptiness';
import Dropdown from '../Dropdown';

class ListSelector extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]).isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  handleChange = (e) => {
    this.props.onChange(JSON.parse(e));
  }

  options = () => {
    return (this.props.field.get('listOptions') || emptyList).map((option) => {
      const optionAsJson = option.toJS();
      optionAsJson.value = JSON.stringify(optionAsJson.value);
      return optionAsJson;
    });
  }

  renderInput = () => {
    return (
      <React.Fragment>
        <Dropdown
          options={this.options()}
          selectedValue={JSON.stringify(this.props.value)}
          onChange={this.handleChange}
          disabled={this.props.prerenderMode}
        />
      </React.Fragment>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <span className="page-block__inline-wrapper">
          {this.props.renderRequired()}
          {this.renderInput()}
          {this.props.renderWarnings()}
        </span>
      );
    }

    return (
      <div className="page-block__field-wrapper" onKeyDown={this.props.listenForSubmit}>
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(ListSelector);
