import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getFastForwardProgress,
} from '../selectors';
import { emptyList } from '../utils/emptiness';

class FastForwarding extends React.Component {
  static propTypes = {
    fastForwardProgress: PropTypes.array,
  }

  static defaultProps = {
    fastForwardProgress: emptyList,
  }

  renderProgress = () => {
    return this.props.fastForwardProgress.map((progressItem) => {
      return (
        <div key={progressItem}>
          {progressItem}
        </div>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        This app is in snapshot mode
        {this.renderProgress()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fastForwardProgress: getFastForwardProgress(state),
  };
};

export default connect(mapStateToProps)(FastForwarding);
