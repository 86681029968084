import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSandbox,
  makeGetTextFor,
} from '../selectors';

class TestModeBadge extends React.Component {
  static propTypes = {
    sandbox: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  render() {
    if (!this.props.sandbox) return null;

    return (
      <div className="test-mode-badge">
        <div className="test-mode-badge__right-col">
          <p className="test-mode-badge__title">
            {this.props.getTextFor('sandbox-mode')}
          </p>
          <p className="test-mode-badge__sub-title">
            {this.props.getTextFor('app-is-sandbox')}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const sandbox = getSandbox(state);

  return {
    sandbox,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(TestModeBadge);
