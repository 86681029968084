const fileUploadVariableTransformer = (variables, getTextFor) => {
  const transformedVariables = { ...variables };
  const uploadValidationsWarningObject = {};

  const pendingFileUploadPromises = Object.entries(transformedVariables).filter(([_variableName, value]) => value && value.presignedS3Url)
    .map(([variableName, value]) => uploadFileToS3(variableName, value, getTextFor));

  // If there were no file upload fields, then pendingFileUploadPromises is an empty array, andPromise.all completes
  // immediately and promiseValues is also an empty array.

  return Promise.all(pendingFileUploadPromises).then((promiseValues) => {
    promiseValues.forEach((value) => {
      const {
        variableName,
        filename,
        s3_key,
        errorText,
        fieldId,
      } = value;

      if (errorText) {
        // If there was an error returned by AWS, we store that in the warnings object and pass it to the FieldValidatons component.
        // Note: currently this text is ignored by the Validation component as it's usually an enormous XML blob, and instead we show a generic error.
        uploadValidationsWarningObject[fieldId] = { fileUploadFailed: { filename, errorText } };
      } else {
        // We overwrite the variable's value with the hash structure which is persisted in the app state.
        transformedVariables[variableName] = { filename, s3_key };
      }
    });
  }).then(() => {
    return { transformedVariables, uploadValidationsWarningObject };
  });
};

const uploadFileToS3 = (variableName, value, getTextFor) => {
  const {
    filename,
    fieldId,
    presignedS3Url,
    attachment,
  } = value;
  const { uploadUrl, s3Key } = presignedS3Url;

  // We return the Promise.
  return fetch(uploadUrl, {
    method: 'PUT',
    body: attachment,
  }).then((resp) => {
    // If the Promise completes successfully, this is the return value accessed within Promise.all.
    if (resp.ok) {
      return { variableName, filename, s3_key: s3Key };
    }

    // If the fetch fails, we return the text of the error which is extracted from the text() Promise.
    return resp.text().then((text) => ({ errorText: text, filename, fieldId }));
  // Fetch generally only throws an error on network failure.
  }).catch((_err) => {
    const errorText = getTextFor('validations.upload-failed-network-error');
    return { errorText, filename, fieldId };
  });
};

export default fileUploadVariableTransformer;
