import dayjs from 'dayjs';
import { isReallyNaN } from '../utils';

// adapted from app/lib/constant_evaluator.rb

export const isNumeric = (reference) => {
  return !isReallyNaN(Number(reference));
};

const wrappedInSinglequote = (reference) => {
  return reference.startsWith("'") && reference.endsWith("'");
};

const wrappedInDoublequote = (reference) => {
  return reference.startsWith('"') && reference.endsWith('"');
};

const isStringlike = (reference) => {
  return wrappedInSinglequote(reference) || wrappedInDoublequote(reference);
};

const isDatelike = (reference) => {
  return dayjs(reference).isValid();
};

export const evaluateNumeric = (reference) => {
  return Number(reference);
};

const evaluateString = (reference) => {
  const length = reference.length;
  return reference.slice(1, (length - 1));
};

const evaluateDate = (reference) => {
  return dayjs(reference);
};

// what's exposed:

export const isConstant = (reference) => {
  return isNumeric(reference) || isStringlike(reference) || isDatelike(reference);
};

export const evaluateConstant = (reference) => {
  if (isNumeric(reference)) {
    return evaluateNumeric(reference);
  }
  if (isStringlike(reference)) {
    return evaluateString(reference);
  }
  if (isDatelike(reference)) {
    return evaluateDate(reference);
  }
  return '';
};
