import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getIsWorking,
  makeGetTextFor,
} from '../selectors';
import { startWorking, stopWorking } from '../actions/ui';
import MaskedInputWrapper from './MaskedInputWrapper';
import { cardExpirationDateMask } from '../utils/masks';

class LawpayCheckout extends React.Component {
  static propTypes = {
    onToken: PropTypes.func.isRequired,
    startWorking: PropTypes.func.isRequired,
    working: PropTypes.bool.isRequired,
    paymentAmountDisplay: PropTypes.string.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
      cardName: '',
      cardExp: '',
      cardCvc: '',
      cardAddress: '',
      cardAddress2: '',
      cardCity: '',
      cardState: '',
      cardZipcode: '',
      email: '',
    };
  }

  updateCardName = (e) => {
    this.setState({
      cardName: e.target.value,
    });
  }

  updateEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  }

  updateCardAddress = (e) => {
    this.setState({
      cardAddress: e.target.value,
    });
  }

  updateCardAddress2 = (e) => {
    this.setState({
      cardAddress2: e.target.value,
    });
  }

  updateCardZipcode = (e) => {
    this.setState({
      cardZipcode: e.target.value,
    });
  }

  updateCardCity = (e) => {
    this.setState({
      cardCity: e.target.value,
    });
  }

  updateCardState = (e) => {
    this.setState({
      cardState: e.target.value,
    });
  }

  updateCardNumber = (e) => {
    this.setState({
      cardNumber: e.target.value,
    });
  }

  updateCardExp = (e) => {
    this.setState({
      cardExp: e.target.value,
    });
  }

  updateCardCvc = (e) => {
    this.setState({
      cardCvc: e.target.value,
    });
  }

  handleSubmit = () => {
    this.props.startWorking();
    const date = this.state.cardExp;
    const month = date.split('/')[0];
    const year = date.split('/')[1];
    const lawpayParams = {
      name: this.state.cardName,
      number: this.state.cardNumber,
      cvv: this.state.cardCvc,
      exp_month: month,
      exp_year: year,
      address1: this.state.cardAddress,
      address2: this.state.cardAddress2,
      city: this.state.cardCity,
      state: this.state.cardState,
      postal_code: this.state.cardZipcode,
      email: this.state.email,
    };
    window.ChargeIO.create_token(lawpayParams, (token) => {
      const tokenId = token.id;
      this.props.onToken(tokenId, this.state.email);
    });
  }

  renderNameField = () => {
    return (
      <React.Fragment>
        <div className="page-block__field-label">
          <label htmlFor="cardholder-name">
            {this.props.getTextFor('payments.cardholder-name')}
          </label>
        </div>
        <div className="page-block__field-input">
          <input id="cardholder-name" className="short-text-input" type="text" value={this.state.cardName} onChange={this.updateCardName} placeholder={this.props.getTextFor('payments.cardholder-name-placeholder')} />
        </div>
      </React.Fragment>
    );
  }

  renderEmailField = () => {
    return (
      <React.Fragment>
        <div className="page-block__field-label">
          <label htmlFor="email">
            {this.props.getTextFor('save-and-continue.email-address')}
          </label>
        </div>
        <div className="page-block__field-input">
          <input id="lawpay-email-address" className="short-text-input" type="text" value={this.state.email} onChange={this.updateEmail} placeholder={this.props.getTextFor('save-and-continue.email-address')} />
        </div>
      </React.Fragment>
    );
  }

  renderAddress1Field = () => {
    return (
      <div className="page-block__lawpay--address-field">
        <input className="short-text-input" type="text" value={this.state.cardAddress} onChange={this.updateCardAddress} placeholder={this.props.getTextFor('payments.address-1')} />
      </div>
    );
  }

  renderAddress2Field = () => {
    return (
      <div className="page-block__lawpay--address-field">
        <input className="short-text-input" type="text" value={this.state.cardAddress2} onChange={this.updateCardAddress2} placeholder={this.props.getTextFor('payments.address-2')} />
      </div>
    );
  }

  renderAddressCityField = () => {
    return (
      <div className="page-block__lawpay--col-item-33">
        <input className="short-text-input" type="text" value={this.state.cardCity} onChange={this.updateCardCity} placeholder={this.props.getTextFor('payments.city')} />
      </div>
    );
  }

  renderAddressStateField = () => {
    return (
      <div className="page-block__lawpay--col-item-33">
        <input className="short-text-input" type="text" value={this.state.cardState} onChange={this.updateCardState} placeholder={this.props.getTextFor('payments.state')} />
      </div>
    );
  }

  renderZipcodeField = () => {
    return (
      <div className="page-block__lawpay--col-item-33">
        <input className="short-text-input" type="text" value={this.state.cardZipcode} onChange={this.updateCardZipcode} placeholder={this.props.getTextFor('payments.zip')} />
      </div>
    );
  }

  renderCardExpirationField = () => {
    return (
      <React.Fragment>
        <div className="page-block__field-label">
          <label htmlFor="card-expiration">
            {this.props.getTextFor('payments.expiration-date')}
          </label>
        </div>
        <div className="page-block__field-input">
          <MaskedInputWrapper
            value={this.state.cardExp}
            onChange={this.updateCardExp}
            mask={cardExpirationDateMask}
            placeholder="MM/YY"
            id="card-expiration"
          />
        </div>
      </React.Fragment>
    );
  }

  renderCardNumberField = () => {
    return (
      <React.Fragment>
        <div className="page-block__field-label">
          <p>
            {this.props.getTextFor('payments.card-number')}
          </p>
        </div>
        <div className="page-block__field-input">
          <input className="short-text-input" type="text" value={this.state.cardNumber} onChange={this.updateCardNumber} placeholder={this.props.getTextFor('payments.card-number')} />
        </div>
      </React.Fragment>
    );
  }

  renderCardCvcField = () => {
    return (
      <React.Fragment>
        <div className="page-block__field-label">
          <label htmlFor="card-cvc">
            {this.props.getTextFor('payments.security-code')}
          </label>
        </div>
        <div className="page-block__field-input">
          <input id="card-cvc" type="text" value={this.state.cardCvc} onChange={this.updateCardCvc} placeholder={this.props.getTextFor('payments.security-code-placeholder')} />
        </div>
      </React.Fragment>
    );
  }

  submitButtonCssClass = () => {
    if (this.props.working) {
      return 'btn-primary disabled';
    }
    return 'btn-primary';
  }

  renderSubmit = () => {
    return (
      <button className={this.submitButtonCssClass()} onClick={this.handleSubmit} disabled={this.props.working}>
        <i className="fas fa-spinner fa-spin" />
        {this.props.getTextFor('payments.pay')} {this.props.paymentAmountDisplay}
      </button>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-block__field-wrapper">
          {this.renderNameField()}
        </div>
        <div className="page-block__field-wrapper">
          {this.renderEmailField()}
        </div>
        <div className="page-block__lawpay--address-wrapper">
          <p className="page-block__lawpay--address-label">
            {this.props.getTextFor('payments.billing-address')}
          </p>
          {this.renderAddress1Field()}
          {this.renderAddress2Field()}
          <div className="page-block__lawpay--cols-wrapper">
            {this.renderAddressCityField()}
            {this.renderAddressStateField()}
            {this.renderZipcodeField()}
          </div>
        </div>
        <p className="page-block__lawpay--address-label">
          {this.props.getTextFor('payments.billing-information')}
        </p>
        <div className="page-block__field-wrapper">
          {this.renderCardNumberField()}
        </div>
        <div className="page-block__lawpay--cols-wrapper">
          <div className="page-block__lawpay--col-item-50">
            {this.renderCardExpirationField()}
          </div>
          <div className="page-block__lawpay--col-item-50">
            {this.renderCardCvcField()}
          </div>
        </div>
        <div className="page-block__submit">
          <div className="page-block__submit--button-row">
            {this.renderSubmit()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  startWorking: (data) => dispatch(startWorking(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LawpayCheckout);
