/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { getSelectedLanguage } from '../selectors';

// Note: We'll have to do this import/call for every single localization we want to have available to us.
registerLocale('es', es);

class DatePickerElement extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    inline: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    value: null,
    disabled: false,
  }

  onDateChange = (date) => {
    // See https://stackoverflow.com/a/50130338/3439498
    // We store the date as YYYY/MM/DD because safari breaks with YYYY-MM-DD
    let dateAsString = null;

    if (date) {
      dateAsString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '/');
    }

    this.props.onSave(dateAsString);
  }

  render() {
    const dateValue = this.props.value ? new Date(`${this.props.value} 00:00`) : null;
    const onChange = (date) => this.onDateChange(date);

    const datepickerProps = {};
    if (dateValue) datepickerProps.selected = dateValue;

    const { field, inline } = this.props;
    let placeholderText = 'MM/DD/YYYY';
    if (inline && field.get('inlinePlaceholder')) placeholderText = field.get('inlinePlaceholder');

    return (
      <DatePicker
        {...datepickerProps}
        placeholderText={placeholderText}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        isClearable
        className="short-text-input"
        id={`field-${this.props.id}`}
        locale={this.props.selectedLanguage}
        disabled={this.props.disabled}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: getSelectedLanguage(state),
  };
};

export default connect(mapStateToProps)(DatePickerElement);
