import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBlock,
  getField,
  makeOnscreenValueForVariable,
} from '../../selectors';
import { updateOnscreen } from '../../actions/onscreen';
import SignatureField from '../fields/SignatureField';
import SignatureActions from './SignatureActions';
import MarkdownRenderer from '../MarkdownRenderer';

class Signature extends React.Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    fieldId: PropTypes.string.isRequired,
    signatureField: PropTypes.object.isRequired,
    updateOnscreen: PropTypes.func.isRequired,
    onscreenValueForVariable: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      signaturePadElement: null,
    };
  }

  setSignaturePadElement = (signaturePadElement) => {
    this.setState({ signaturePadElement });
  }

  renderSubquestion = () => {
    return (
      <div className="page-block__markdownRenderer">
        <MarkdownRenderer source={this.props.block.get('subquestion')} />
      </div>
    );
  }

  renderField = () => {
    const { signatureField, fieldId, onscreenValueForVariable } = this.props;

    return (
      <div className="page-block__field-wrapper" role="form">
        <SignatureField
          id={fieldId}
          setSignaturePadElement={this.setSignaturePadElement}
          value={onscreenValueForVariable(signatureField.get('immutableVariableName')) || ''}
          key={fieldId}
        />
      </div>
    );
  }

  onClear = () => {
    if (this.state.signaturePadElement) {
      this.state.signaturePadElement.clear();

      const immutableVariableName = this.props.signatureField.get('immutableVariableName');
      this.props.updateOnscreen({ [immutableVariableName]: null });
    }
  }

  renderSubmit = () => {
    return (
      <SignatureActions onClear={this.onClear} />
    );
  }

  render() {
    return (
      <div className="page-block page-block__question animate__animated">
        {this.renderSubquestion()}
        {this.renderField()}
        {this.renderSubmit()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const block = getBlock(state);
  const fieldId = block.get('fields').first();
  const signatureField = getField(state, fieldId);
  const onscreenValueForVariable = makeOnscreenValueForVariable(state);

  return {
    block,
    fieldId,
    signatureField,
    onscreenValueForVariable,
  };
};

const mapDispatchToProps = dispatch => ({
  updateOnscreen: data => dispatch(updateOnscreen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signature);
