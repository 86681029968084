import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import {
  getFileAttachment,
  getVariableValue,
} from '../selectors';
import { isIosChrome, isIosSafari } from '../utils/agent';

class BlockFileAttachment extends React.Component {
  static propTypes = {
    attachmentId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    attachment: PropTypes.object.isRequired,
    variableValue: PropTypes.object,
  }

  static defaultProps = {
    variableValue: null,
  }

  saveFile = () => {
    const { variableValue, attachment } = this.props;
    const fileUrl = variableValue.get('file_url');
    const attachmentFilename = attachment.get('attachmentFilename') || variableValue.get('filename');

    fetch(fileUrl)
      .then((resp) => resp.blob())
      .then((data) => FileSaver.saveAs(data, attachmentFilename));
  }

  // NOTE: without using FileSaver, the downloaded document won't have the correct filename
  // (will have the filename of the template, not the custom attachment filename).
  renderAttachment = () => {
    const { variableValue, attachment } = this.props;

    if (!variableValue || !attachment) return null;

    const isFileSaverSupported = (!!(new Blob())) && (!isIosChrome()) && (!isIosSafari());
    const fileUrl = variableValue.get('file_url');
    const attachmentFilename = attachment.get('attachmentFilename') || variableValue.get('filename');

    const saveFileElement = isFileSaverSupported ? (
      <button onClick={this.saveFile}>
        <i className="far fa-file-alt" />
        {attachmentFilename}
      </button>
    ) : (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <i className="far fa-file-alt" />
        <span className="attachment-item__file-name">
          {attachmentFilename}
        </span>
      </a>
    );
    return (
      <div className="attachment-item__complete">
        {saveFileElement}
      </div>
    );
  }

  render() {
    // This means that the file upload was a non-required field and was left blank.
    if (!this.props.variableValue.size) return null;

    return (
      <div className="attachment-item">
        {this.renderAttachment()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { attachmentId } = ownProps;
  const attachment = getFileAttachment(state, attachmentId);
  const variableName = attachment.get('variableName');
  const variableValue = getVariableValue(state, variableName);

  return {
    attachment,
    variableValue,
  };
};

export default connect(mapStateToProps)(BlockFileAttachment);
