import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getField } from '../../selectors';

// Note: for ways to programmatically trigger changes to input values which React is aware of (and thus
// updates the onscreen value), see:
// https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js

class JavascriptField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    field: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const jsCode = this.props.field.get('label');
    const functionToEvaluate = new Function(jsCode);

    if (window.Rollbar) window.Rollbar.configure({ enabled: false });

    functionToEvaluate();

    if (window.Rollbar) window.Rollbar.configure({ enabled: true });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  return {
    field,
  };
};

export default connect(mapStateToProps)(JavascriptField);
