import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getActivated,
  makeGetTextFor,
} from '../selectors';

class TestModeBadge extends React.Component {
  static propTypes = {
    activated: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  render() {
    if (this.props.activated) return null;

    return (
      <div className="test-mode-badge">
        <p className="test-mode-badge__title">
          {this.props.getTextFor('test-mode')}
        </p>
        <p className="test-mode-badge__sub-title">
          {this.props.getTextFor('app-not-activated')}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const activated = getActivated(state);

  return {
    activated,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(TestModeBadge);
