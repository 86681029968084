import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getAppName,
  getAppLogo,
  getExitButtonUrl,
  getIncludeExitButton,
  makeGetTextFor,
} from '../selectors';

class Navbar extends React.Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
    exitButtonUrl: PropTypes.string.isRequired,
    appLogo: PropTypes.string,
    includeExitButton: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  static defaultProps = {
    appLogo: null,
  }

  renderAppName = () => {
    const { appLogo, appName } = this.props;
    if (appLogo) return '';

    return (
      <span className="app-name">
        {appName}
      </span>
    );
  }

  renderAppLogo = () => {
    const { appLogo, appName } = this.props;

    if (!appLogo) return null;

    return (
      <React.Fragment>
        <img className="app-logo" alt={appName} src={appLogo} />
      </React.Fragment>
    );
  }

  renderExitButton = () => {
    if (!this.props.includeExitButton) return null;

    return (
      <React.Fragment>
        <a className="btn-exit" href={this.props.exitButtonUrl}>
          {this.props.getTextFor('exit')}
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="navbar" role="banner">
        <div className="navbar__wrapper">
          <div className="navbar__left-col">
            {this.renderAppLogo()}
            {this.renderAppName()}
          </div>
          <div className="navbar__right-col">
            {this.renderExitButton()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const appName = getAppName(state);
  const appLogo = getAppLogo(state);
  const exitButtonUrl = getExitButtonUrl(state);

  return {
    appName,
    appLogo,
    exitButtonUrl,
    includeExitButton: getIncludeExitButton(state),
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(Navbar);
