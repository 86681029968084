import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('body');

class ModalWrapper extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    shouldCloseOnEsc: PropTypes.bool,
    onRequestClose: PropTypes.func,
    children: PropTypes.object,
  };

  static defaultProps = {
    isOpen: false,
    shouldCloseOnEsc: true,
    children: null,
    onRequestClose: () => {},
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        shouldCloseOnOverlayClick
        onRequestClose={this.props.onRequestClose}
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default ModalWrapper;
