import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSession, getPrerenderMode } from '../selectors';
import AppLandingPage from './AppLandingPage';
import AppSession from './AppSession';
import ActionCableListener from './ActionCableListener';

class AppWrapper extends React.Component {
  static propTypes = {
    sessionId: PropTypes.string,
    sessionKey: PropTypes.string,
    _accessKey: PropTypes.string,
    session: PropTypes.object,
    urlParams: PropTypes.string,
    appId: PropTypes.string,
    prerenderMode: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    session: null,
    sessionId: null,
    sessionKey: null,
    _accessKey: null,
    urlParams: '',
    appId: null,
  }

  componentDidMount = () => {
    // clear the dev mode key out of the url if it's there so it doesn't accidentally get included in a copy/pasted url
    if (window.location.href.includes('developer_mode_key') || window.location.href.includes('session_key')) {
      if (window.history.replaceState) {
        window.history.replaceState(
          null,
          '',
          window.location.pathname + window.location.search
            .replace(/[?&]developer_mode_key=[^&]+/, '')
            .replace(/[?&]session_key=[^&]+/, '')
            .replace(/^&/, '?')
            .replace(/\?$/, ''),
        );
      }
    }
  }

  renderInterior = () => {
    if (this.props.session) {
      return (
        <AppSession
          appId={this.props.appId}
          _accessKey={this.props._accessKey}
          sessionKey={this.props.sessionKey}
          urlParams={this.props.urlParams}
        />
      );
    }

    return (
      <AppLandingPage
        appId={this.props.appId}
        _accessKey={this.props._accessKey}
        sessionId={this.props.sessionId}
        sessionKey={this.props.sessionKey}
        urlParams={this.props.urlParams}
      />
    );
  }

  renderListener = () => {
    if (this.props.prerenderMode) {
      return '';
    }
    return (
      <ActionCableListener />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderListener()}
        {this.renderInterior()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const session = getSession(state);
  const prerenderMode = session ? getPrerenderMode(state) : false;

  return {
    session,
    prerenderMode,
  };
};

export default connect(mapStateToProps)(AppWrapper);
