export const idempotentStringAsBool = (input) => {
  if (input === true || input === 'true') {
    return true;
  }
  if (input === false || input === 'false') {
    return false;
  }
  return null;
};

export const isReallyNaN = (input) => {
  return input !== input;
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidEmail = (email) => {
  return emailRegex.test(email);
};

export const formatAsCurrency = (cents, currency) => {
  const currencySymbol = currencies[currency].symbol;
  const zeroDecimal = currencies[currency].zero_decimal;
  if (zeroDecimal) {
    return `${currencySymbol}${cents}`;
  }
  return `${currencySymbol}${(cents / 100.0).toFixed(2)}`;
};

export const ENTER = 13;
export const SPACEBAR = 32;

export const appPath = () => {
  return document.querySelector('#app_path').value;
};

export const currencies = {
  USD: {
    label: 'United States dollar',
    value: 'USD',
    zero_decimal: false,
    symbol: '$',
  },
  CAD: {
    label: 'Canadian dollar',
    value: 'CAD',
    zero_decimal: false,
    symbol: '$',
  },
  EUR: {
    label: 'European euro',
    value: 'EUR',
    zero_decimal: false,
    symbol: '€',
  },
  AFN: {
    label: 'Afghan afghani',
    value: 'AFN',
    zero_decimal: false,
    symbol: '؋',
  },
  ALL: {
    label: 'Albanian lek',
    value: 'ALL',
    zero_decimal: false,
    symbol: 'L',
  },
  DZD: {
    label: 'Algerian dinar',
    value: 'DZD',
    zero_decimal: false,
    symbol: 'دج',
  },
  AOA: {
    label: 'Angolan kwanza',
    value: 'AOA',
    zero_decimal: false,
    symbol: 'Kz',
  },
  XCD: {
    label: 'East Caribbean dollar',
    value: 'XCD',
    zero_decimal: false,
    symbol: '$',
  },
  ARS: {
    label: 'Argentine peso',
    value: 'ARS',
    zero_decimal: false,
    symbol: '$',
  },
  AMD: {
    label: 'Armenian dram',
    value: 'AMD',
    zero_decimal: false,
    symbol: '֏',
  },
  AWG: {
    label: 'Aruban florin',
    value: 'AWG',
    zero_decimal: false,
    symbol: 'ƒ',
  },
  SHP: {
    label: 'Saint Helena pound',
    value: 'SHP',
    zero_decimal: false,
    symbol: '£',
  },
  AUD: {
    label: 'Australian dollar',
    value: 'AUD',
    zero_decimal: false,
    symbol: '$',
  },
  AZN: {
    label: 'Azerbaijan manat',
    value: 'AZN',
    zero_decimal: false,
    symbol: '₼',
  },
  BSD: {
    label: 'Bahamian dollar',
    value: 'BSD',
    zero_decimal: false,
    symbol: '$',
  },
  BDT: {
    label: 'Bangladeshi taka',
    value: 'BDT',
    zero_decimal: false,
    symbol: '৳',
  },
  BBD: {
    label: 'Barbadian dollar',
    value: 'BBD',
    zero_decimal: false,
    symbol: '$',
  },
  BZD: {
    label: 'Belize dollar',
    value: 'BZD',
    zero_decimal: false,
    symbol: 'BZ$',
  },
  XOF: {
    label: 'West African CFA franc',
    value: 'XOF',
    zero_decimal: true,
    symbol: 'CFA',
  },
  BMD: {
    label: 'Bermudian dollar',
    value: 'BMD',
    zero_decimal: false,
    symbol: '$',
  },
  BOB: {
    label: 'Bolivian boliviano',
    value: 'BOB',
    zero_decimal: false,
    symbol: '$b',
  },
  BAM: {
    label: 'Bosnia and Herzegovina convertible mark',
    value: 'BAM',
    zero_decimal: false,
    symbol: 'KM',
  },
  BWP: {
    label: 'Botswana pula',
    value: 'BWP',
    zero_decimal: false,
    symbol: 'P',
  },
  BRL: {
    label: 'Brazilian real',
    value: 'BRL',
    zero_decimal: false,
    symbol: 'R$',
  },
  BND: {
    label: 'Brunei dollar',
    value: 'BND',
    zero_decimal: false,
    symbol: '$',
  },
  BGN: {
    label: 'Bulgarian lev',
    value: 'BGN',
    zero_decimal: false,
    symbol: 'лв',
  },
  BIF: {
    label: 'Burundi franc',
    value: 'BIF',
    zero_decimal: true,
    symbol: 'FBu',
  },
  CVE: {
    label: 'Cape Verdean escudo',
    value: 'CVE',
    zero_decimal: false,
    symbol: '$',
  },
  KHR: {
    label: 'Cambodian riel',
    value: 'KHR',
    zero_decimal: false,
    symbol: '៛',
  },
  XAF: {
    label: 'Central African CFA franc',
    value: 'XAF',
    zero_decimal: true,
    symbol: 'FCFA',
  },
  KYD: {
    label: 'Cayman Islands dollar',
    value: 'KYD',
    zero_decimal: false,
    symbol: '$',
  },
  NZD: {
    label: 'New Zealand dollar',
    value: 'NZD',
    zero_decimal: false,
    symbol: '$',
  },
  CLP: {
    label: 'Chilean peso',
    value: 'CLP',
    zero_decimal: true,
    symbol: '$',
  },
  CNY: {
    label: 'Chinese Yuan Renminbi',
    value: 'CNY',
    zero_decimal: false,
    symbol: '¥',
  },
  COP: {
    label: 'Colombian peso',
    value: 'COP',
    zero_decimal: false,
    symbol: '$',
  },
  KMF: {
    label: 'Comorian franc',
    value: 'KMF',
    zero_decimal: true,
    symbol: 'CF',
  },
  CDF: {
    label: 'Congolese franc',
    value: 'CDF',
    zero_decimal: false,
    symbol: 'FC',
  },
  CRC: {
    label: 'Costa Rican colon',
    value: 'CRC',
    zero_decimal: false,
    symbol: '₡',
  },
  HRK: {
    label: 'Croatian kuna',
    value: 'HRK',
    zero_decimal: false,
    symbol: 'kn',
  },
  ANG: {
    label: 'Netherlands Antillean guilder',
    value: 'ANG',
    zero_decimal: false,
    symbol: 'ƒ',
  },
  CZK: {
    label: 'Czech koruna',
    value: 'CZK',
    zero_decimal: false,
    symbol: 'Kč',
  },
  DKK: {
    label: 'Danish krone',
    value: 'DKK',
    zero_decimal: false,
    symbol: 'kr',
  },
  DJF: {
    label: 'Djiboutian franc',
    value: 'DJF',
    zero_decimal: true,
    symbol: 'Fdj',
  },
  DOP: {
    label: 'Dominican peso',
    value: 'DOP',
    zero_decimal: false,
    symbol: 'RD$',
  },
  EGP: {
    label: 'Egyptian pound',
    value: 'EGP',
    zero_decimal: false,
    symbol: '£',
  },
  SZL: {
    label: 'Swazi lilangeni',
    value: 'SZL',
    zero_decimal: false,
    symbol: 'E',
  },
  ETB: {
    label: 'Ethiopian birr',
    value: 'ETB',
    zero_decimal: false,
    symbol: 'Br',
  },
  FKP: {
    label: 'Falkland Islands pound',
    value: 'FKP',
    zero_decimal: false,
    symbol: '£',
  },
  FJD: {
    label: 'Fijian dollar',
    value: 'FJD',
    zero_decimal: false,
    symbol: '$',
  },
  XPF: {
    label: 'CFP franc',
    value: 'XPF',
    zero_decimal: true,
    symbol: '₣',
  },
  GMD: {
    label: 'Gambian dalasi',
    value: 'GMD',
    zero_decimal: false,
    symbol: 'D',
  },
  GEL: {
    label: 'Georgian lari',
    value: 'GEL',
    zero_decimal: false,
    symbol: '₾',
  },
  GIP: {
    label: 'Gibraltar pound',
    value: 'GIP',
    zero_decimal: false,
    symbol: '£',
  },
  GTQ: {
    label: 'Guatemalan quetzal',
    value: 'GTQ',
    zero_decimal: false,
    symbol: 'Q',
  },
  GNF: {
    label: 'Guinean franc',
    value: 'GNF',
    zero_decimal: true,
    symbol: 'FG',
  },
  GYD: {
    label: 'Guyanese dollar',
    value: 'GYD',
    zero_decimal: false,
    symbol: '$',
  },
  HTG: {
    label: 'Haitian gourde',
    value: 'HTG',
    zero_decimal: false,
    symbol: 'G',
  },
  HNL: {
    label: 'Honduran lempira',
    value: 'HNL',
    zero_decimal: false,
    symbol: 'L',
  },
  HKD: {
    label: 'Hong Kong dollar',
    value: 'HKD',
    zero_decimal: false,
    symbol: '$',
  },
  HUF: {
    label: 'Hungarian forint',
    value: 'HUF',
    zero_decimal: false,
    symbol: 'Ft',
  },
  ISK: {
    label: 'Icelandic krona',
    value: 'ISK',
    zero_decimal: false,
    symbol: 'kr',
  },
  INR: {
    label: 'Indian rupee',
    value: 'INR',
    zero_decimal: false,
    symbol: '₹',
  },
  IDR: {
    label: 'Indonesian rupiah',
    value: 'IDR',
    zero_decimal: false,
    symbol: 'Rp',
  },
  ILS: {
    label: 'Israeli new shekel',
    value: 'ILS',
    zero_decimal: false,
    symbol: '₪',
  },
  JMD: {
    label: 'Jamaican dollar',
    value: 'JMD',
    zero_decimal: false,
    symbol: 'J$',
  },
  JPY: {
    label: 'Japanese yen',
    value: 'JPY',
    zero_decimal: true,
    symbol: '¥',
  },
  KZT: {
    label: 'Kazakhstani tenge',
    value: 'KZT',
    zero_decimal: false,
    symbol: 'лв',
  },
  KES: {
    label: 'Kenyan shilling',
    value: 'KES',
    zero_decimal: false,
    symbol: 'KSh',
  },
  KGS: {
    label: 'Kyrgyzstani som',
    value: 'KGS',
    zero_decimal: false,
    symbol: 'лв',
  },
  LAK: {
    label: 'Lao kip',
    value: 'LAK',
    zero_decimal: false,
    symbol: '₭',
  },
  LBP: {
    label: 'Lebanese pound',
    value: 'LBP',
    zero_decimal: false,
    symbol: '£',
  },
  LSL: {
    label: 'Lesotho loti',
    value: 'LSL',
    zero_decimal: false,
    symbol: 'M',
  },
  LRD: {
    label: 'Liberian dollar',
    value: 'LRD',
    zero_decimal: false,
    symbol: '$',
  },
  CHF: {
    label: 'Swiss franc',
    value: 'CHF',
    zero_decimal: false,
    symbol: 'CHF',
  },
  MOP: {
    label: 'Macanese pataca',
    value: 'MOP',
    zero_decimal: false,
    symbol: 'MOP$',
  },
  MGA: {
    label: 'Malagasy ariary',
    value: 'MGA',
    zero_decimal: true,
    symbol: 'Ar',
  },
  MRO: {
    label: 'Mauritanian Ouguiya',
    value: 'MRO',
    zero_decimal: false,
    symbol: 'UM',
  },
  MWK: {
    label: 'Malawian kwacha',
    value: 'MWK',
    zero_decimal: false,
    symbol: 'MK',
  },
  MYR: {
    label: 'Malaysian ringgit',
    value: 'MYR',
    zero_decimal: false,
    symbol: 'RM',
  },
  MVR: {
    label: 'Maldivian rufiyaa',
    value: 'MVR',
    zero_decimal: false,
    symbol: 'Rf',
  },
  MUR: {
    label: 'Mauritian rupee',
    value: 'MUR',
    zero_decimal: false,
    symbol: '₨',
  },
  MXN: {
    label: 'Mexican peso',
    value: 'MXN',
    zero_decimal: false,
    symbol: '$',
  },
  MDL: {
    label: 'Moldovan leu',
    value: 'MDL',
    zero_decimal: false,
    symbol: 'lei',
  },
  MNT: {
    label: 'Mongolian tugrik',
    value: 'MNT',
    zero_decimal: false,
    symbol: '₮',
  },
  MAD: {
    label: 'Moroccan dirham',
    value: 'MAD',
    zero_decimal: false,
    symbol: 'MAD',
  },
  MZN: {
    label: 'Mozambican metical',
    value: 'MZN',
    zero_decimal: false,
    symbol: 'MT',
  },
  MMK: {
    label: 'Myanmar kyat',
    value: 'MMK',
    zero_decimal: false,
    symbol: 'K',
  },
  NAD: {
    label: 'Namibian dollar',
    value: 'NAD',
    zero_decimal: false,
    symbol: '$',
  },
  NPR: {
    label: 'Nepalese rupee',
    value: 'NPR',
    zero_decimal: false,
    symbol: '₨',
  },
  NIO: {
    label: 'Nicaraguan cordoba',
    value: 'NIO',
    zero_decimal: false,
    symbol: 'C$',
  },
  NGN: {
    label: 'Nigerian naira',
    value: 'NGN',
    zero_decimal: false,
    symbol: '₦',
  },
  MKD: {
    label: 'Macedonian denar',
    value: 'MKD',
    zero_decimal: false,
    symbol: 'ден',
  },
  NOK: {
    label: 'Norwegian krone',
    value: 'NOK',
    zero_decimal: false,
    symbol: 'kr',
  },
  PAB: {
    label: 'Panamanian Balboa',
    value: 'PAB',
    zero_decimal: false,
    symbol: 'B/.',
  },
  PKR: {
    label: 'Pakistani rupee',
    value: 'PKR',
    zero_decimal: false,
    symbol: '₨',
  },
  PGK: {
    label: 'Papua New Guinean kina',
    value: 'PGK',
    zero_decimal: false,
    symbol: 'K',
  },
  PYG: {
    label: 'Paraguayan guarani',
    value: 'PYG',
    zero_decimal: true,
    symbol: 'Gs',
  },
  PEN: {
    label: 'Peruvian sol',
    value: 'PEN',
    zero_decimal: false,
    symbol: 'S/.',
  },
  PHP: {
    label: 'Philippine peso',
    value: 'PHP',
    zero_decimal: false,
    symbol: '₱',
  },
  PLN: {
    label: 'Polish zloty',
    value: 'PLN',
    zero_decimal: false,
    symbol: 'zł',
  },
  QAR: {
    label: 'Qatari riyal',
    value: 'QAR',
    zero_decimal: false,
    symbol: '﷼',
  },
  RON: {
    label: 'Romanian leu',
    value: 'RON',
    zero_decimal: false,
    symbol: 'lei',
  },
  RUB: {
    label: 'Russian ruble',
    value: 'RUB',
    zero_decimal: false,
    symbol: '₽',
  },
  RWF: {
    label: 'Rwandan franc',
    value: 'RWF',
    zero_decimal: true,
    symbol: 'R₣',
  },
  WST: {
    label: 'Samoan tala',
    value: 'WST',
    zero_decimal: false,
    symbol: 'WS$',
  },
  SAR: {
    label: 'Saudi Arabian riyal',
    value: 'SAR',
    zero_decimal: false,
    symbol: '﷼',
  },
  RSD: {
    label: 'Serbian dinar',
    value: 'RSD',
    zero_decimal: false,
    symbol: 'Дин.',
  },
  SCR: {
    label: 'Seychellois rupee',
    value: 'SCR',
    zero_decimal: false,
    symbol: '₨',
  },
  SLL: {
    label: 'Sierra Leonean leone',
    value: 'SLL',
    zero_decimal: false,
    symbol: 'Le',
  },
  SGD: {
    label: 'Singapore dollar',
    value: 'SGD',
    zero_decimal: false,
    symbol: '$',
  },
  SBD: {
    label: 'Solomon Islands dollar',
    value: 'SBD',
    zero_decimal: false,
    symbol: '$',
  },
  SOS: {
    label: 'Somali shilling',
    value: 'SOS',
    zero_decimal: false,
    symbol: 'S',
  },
  ZAR: {
    label: 'South African rand',
    value: 'ZAR',
    zero_decimal: false,
    symbol: 'R',
  },
  GBP: {
    label: 'Pound sterling',
    value: 'GBP',
    zero_decimal: false,
    symbol: '£',
  },
  KRW: {
    label: 'South Korean won',
    value: 'KRW',
    zero_decimal: true,
    symbol: '₩',
  },
  LKR: {
    label: 'Sri Lankan rupee',
    value: 'LKR',
    zero_decimal: false,
    symbol: '₨',
  },
  SRD: {
    label: 'Surinamese dollar',
    value: 'SRD',
    zero_decimal: false,
    symbol: '$',
  },
  SEK: {
    label: 'Swedish krona',
    value: 'SEK',
    zero_decimal: false,
    symbol: 'kr',
  },
  STD: {
    label: 'São Tomé and Príncipe',
    value: 'STD',
    zero_decimal: false,
    symbol: 'Db',
  },
  TWD: {
    label: 'New Taiwan dollar',
    value: 'TWD',
    zero_decimal: false,
    symbol: 'NT$',
  },
  TJS: {
    label: 'Tajikistani somoni',
    value: 'TJS',
    zero_decimal: false,
    symbol: 'SM',
  },
  TZS: {
    label: 'Tanzanian shilling',
    value: 'TZS',
    zero_decimal: false,
    symbol: 'TSh',
  },
  THB: {
    label: 'Thai baht',
    value: 'THB',
    zero_decimal: false,
    symbol: '฿',
  },
  TOP: {
    label: 'Tongan pa’anga',
    value: 'TOP',
    zero_decimal: false,
    symbol: 'T$',
  },
  TTD: {
    label: 'Trinidad and Tobago dollar',
    value: 'TTD',
    zero_decimal: false,
    symbol: 'TT$',
  },
  TRY: {
    label: 'Turkish lira',
    value: 'TRY',
    zero_decimal: false,
    symbol: '₺',
  },
  UGX: {
    label: 'Ugandan shilling',
    value: 'UGX',
    zero_decimal: true,
    symbol: 'USh',
  },
  UAH: {
    label: 'Ukrainian hryvnia',
    value: 'UAH',
    zero_decimal: false,
    symbol: '₴',
  },
  AED: {
    label: 'UAE dirham',
    value: 'AED',
    zero_decimal: false,
    symbol: 'د.إ',
  },
  UYU: {
    label: 'Uruguayan peso',
    value: 'UYU',
    zero_decimal: false,
    symbol: '$U',
  },
  UZS: {
    label: 'Uzbekistani som',
    value: 'UZS',
    zero_decimal: false,
    symbol: 'лв',
  },
  VUV: {
    label: 'Vanuatu vatu',
    value: 'VUV',
    zero_decimal: true,
    symbol: 'VT',
  },
  VND: {
    label: 'Vietnamese dong',
    value: 'VND',
    zero_decimal: true,
    symbol: '₫',
  },
  YER: {
    label: 'Yemeni rial',
    value: 'YER',
    zero_decimal: false,
    symbol: '﷼',
  },
  ZMW: {
    label: 'Zambian kwacha',
    value: 'ZMW',
    zero_decimal: false,
    symbol: 'ZK',
  },
};
