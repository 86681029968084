import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { evaluateNumeric } from '../evaluation/constancy';
import { makeGetTextFor } from '../selectors';

const emptyOption = JSON.stringify('');

class Dropdown extends React.Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    getTextFor: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    selectedValue: emptyOption,
    disabled: false,
  }

  onSelectChange = (e) => {
    let { value } = e.target;
    const { type } = e.target.dataset;

    if (type === 'number') value = evaluateNumeric(value);

    this.props.onChange(value);
  }

  renderOptions = () => {
    return this.props.options.map((option) => {
      return (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      );
    });
  }

  render() {
    const firstOption = this.props.options.first() || {};
    const type = typeof firstOption.value;

    return (
      <select
        className="dropdown-input"
        onChange={this.onSelectChange}
        value={this.props.selectedValue}
        disabled={this.props.disabled}
        data-type={type}
      >
        <option value="" key="" disabled={this.props.selectedValue !== emptyOption}>
          {this.props.getTextFor('select-one')}
        </option>
        {this.renderOptions()}
      </select>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(Dropdown);
