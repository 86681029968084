const csrfToken = decodeURIComponent(document.querySelector('[name="csrf-token"]').content);

export const newSessionRequest = (id, access_key, params, success, failure) => {
  const body = JSON.stringify({ compiled_app_id: id, access_key, ...params });
  fetch(`/${id}/new`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data.message);
    }
  });
};

export const loadSessionRequest = (session_id, success, failure) => {
  fetch(`/sessions/${session_id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data.message);
    }
  });
};

export const updateSessionRequest = (app_session_id, session_key, update_type, variables, visited_block_id, loop_index, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
    update_type,
    variables,
    visited_block_id,
    loop_index,
  });

  fetch('/sessions', {
    method: 'PATCH',
    body,
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data);
    }
  });
};

export const submitLawpayPaymentRequest = (app_session_id, session_key, visited_block_id, token, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
    update_type: 'lawpay',
    token,
    visited_block_id,
  });
  fetch('/sessions', {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data.message);
    }
  });
};

export const backRequest = (app_session_id, session_key, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
  });

  fetch('/sessions/back', {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data.message);
    }
  });
};

export const saveAndContinueRequest = (params, success) => {
  const body = JSON.stringify(params);

  fetch('/save_and_continue', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    body,
  }).then(() => {
    success();
  });
};

export const clioSearchRequest = (session_id, session_key, search_term, tableVariableName, success, failure) => {
  fetch(`/clio_search?app_session_id=${session_id}&session_key=${session_key}&search_term=${search_term}&table_variable_name=${tableVariableName}`, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  }).then((response) => {
    return response.json();
  }).then((data) => {
    // Note that because this is being passed to the AsyncSelect component, we need to return the value.
    if (data.success) {
      return success(data.search_results);
    }

    return failure(JSON.stringify(data.message));
  });
};

export const googleSearchRequest = (session_id, session_key, search_term, tableVariableName, success, failure) => {
  fetch(`/google_search?app_session_id=${session_id}&session_key=${session_key}&search_term=${search_term}&table_variable_name=${tableVariableName}`, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  }).then((response) => {
    return response.json();
  }).then((data) => {
    // Note that because this is being passed to the AsyncSelect component, we need to return the value.
    if (data.success) {
      return success(data.search_results);
    }

    return failure(JSON.stringify(data.message));
  });
};

export const cldbSearchRequest = (matchingKeysEndpoint, table_id, access_code, search_term, display_column_id, success, failure) => {
  fetch(`${matchingKeysEndpoint}?query=${search_term}&table_id=${table_id}&access_code=${access_code}&display_column_id=${display_column_id}`, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    // Note that because this is being passed to the AsyncSelect component, we need to return the value.
    if (data.success) {
      return success(data);
    }

    return failure(data.message);
  });
};

export const createSnapshotRequest = (app_session_id, session_key, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
  });
  fetch('/snapshots/create/', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    // this will basically always succeed cause the actual meat of this RPC occurs in a background/sidekiq action
    if (data.success) {
      return success(data);
    }
    return failure(data.message);
  });
};

export const fastForwardRequest = (app_session_id, session_key, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
  });
  fetch('/fast_forward/', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data);
    }
  });
};

export const variableValueRequest = (app_session_id, session_key, variable_name, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
    variable_name,
  });
  fetch('/variable_value/', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data.message);
    }
  });
};

export const goToWaypointRequest = (app_session_id, session_key, waypoint_id, rewinding, variables, visited_block_id, success, failure) => {
  const body = JSON.stringify({
    session_key,
    app_session_id,
    waypoint_id,
    rewinding,
    variables,
    visited_block_id,
  });
  fetch('/waypoint/', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    body,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    if (data.success) {
      success(data.object);
    } else {
      failure(data.message);
    }
  });
};
