import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkdownRenderer from '../MarkdownRenderer';
import { getField } from '../../selectors';

class Note extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    field: PropTypes.object.isRequired,
  }

  render() {
    const label = this.props.field.get('label').replace(/`/g, '');
    return (
      <React.Fragment>
        <MarkdownRenderer
          source={label}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  return {
    field,
  };
};

export default connect(mapStateToProps)(Note);
