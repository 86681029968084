import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSession,
  getBlockId,
  getBackNeedsConfirmation,
  getBackConfirmationText,
  getIsWorking,
  makeGetTextFor,
} from '../../selectors';
import { backRequest } from '../../api';
import { loadNewState } from '../../actions';
import ConfirmationModal from '../ConfirmationModal';
import { startWorking, stopWorking } from '../../actions/ui';

class BackButton extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
    loadNewState: PropTypes.func.isRequired,
    backNeedsConfirmation: PropTypes.bool.isRequired,
    backConfirmationText: PropTypes.string.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    working: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      showConfirmationModal: false,
    };
  }

  showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  }

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  }

  handleBack = () => {
    if (this.props.backNeedsConfirmation) {
      this.showConfirmationModal();
    } else {
      this.performBackRequest();
    }
  }

  performBackRequest = () => {
    this.props.startWorking();
    const success = (object) => {
      this.props.stopWorking();
      this.hideConfirmationModal();
      this.props.loadNewState(object);
    };
    const failure = (message) => {
      this.props.stopWorking();
      this.hideConfirmationModal();
      alert(message);
    };
    const id = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    backRequest(id, sessionKey, success, failure);
  }

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.state.showConfirmationModal}
        onConfirm={this.performBackRequest}
        onCancel={this.hideConfirmationModal}
        className="confirmation-modal"
      >
        <div className="confirmation-modal__header">
          <i className="fas fa-exclamation-triangle"></i>
          <p>
            {this.props.backConfirmationText}
          </p>
        </div>
      </ConfirmationModal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfirmationModal()}
          <button tabIndex="0" className="back-button" onClick={this.handleBack} disabled={this.props.working}>
            <i className="fa fa-chevron-left" />
            {this.props.getTextFor('back')}
          </button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: getSession(state),
    blockId: getBlockId(state),
    backNeedsConfirmation: getBackNeedsConfirmation(state),
    backConfirmationText: getBackConfirmationText(state),
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
  startWorking: (data) => dispatch(startWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackButton);
