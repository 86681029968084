import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';
import MarkdownRenderer from '../MarkdownRenderer';
import FieldValidationWarning from './FieldValidationWarning';
import {
  getField,
  getWarnings,
} from '../../selectors';
import { updateOnscreen } from '../../actions/onscreen';

class SignatureField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    field: PropTypes.object.isRequired,
    setSignaturePadElement: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    updateOnscreen: PropTypes.func.isRequired,
    thisFieldWarning: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }

  static defaultProps = {
    value: null,
    thisFieldWarning: null,
  }

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.state = {
      signaturePadElement: null,
    };
  }

  componentDidMount() {
    const canvasElement = this.canvasRef.current;
    const signaturePadElement = new SignaturePad(canvasElement, {
      onBegin: this.handleChange,
      onEnd: this.handleChange,
    });

    let drawingCanvasFromPreviousValue = false;
    if (this.props.value) {
      this.drawCanvasFromExistingSignature();
      drawingCanvasFromPreviousValue = true;
    }

    this.setState({ signaturePadElement }, () => {
      this.handleChange(drawingCanvasFromPreviousValue);
      this.resizeCanvas(canvasElement);
    });
  }

  /* eslint-disable no-param-reassign */
  resizeCanvas = (canvasElement) => {
    const canvasContainer = document.getElementById('signature-input');
    const canvasWidth = canvasContainer.offsetWidth;
    const canvasHeight = canvasContainer.offsetHeight;
    canvasElement.width = canvasWidth;
    canvasElement.height = canvasHeight;
    this.state.signaturePadElement.clear();
  }
  /* eslint-enable no-param-reassign */

  drawCanvasFromExistingSignature = () => {
    const canvasElement = this.canvasRef.current;
    const ctx = canvasElement.getContext('2d');
    const myImage = new Image();
    myImage.src = this.props.value.get('data_uri');
    myImage.onload = () => ctx.drawImage(myImage, 0, 0);
  }

  handleChange = (drawingCanvasFromPreviousValue) => {
    const { signaturePadElement } = this.state;
    this.props.setSignaturePadElement(signaturePadElement);

    const immutableVariableName = this.props.field.get('immutableVariableName');

    if (signaturePadElement.isEmpty()) {
      // If we've reloaded the page and re-drew the canvas from an existing value,
      // we don't want to null out the onscreen value.
      if (!drawingCanvasFromPreviousValue) {
        this.props.updateOnscreen({ [immutableVariableName]: null });
      }
    } else {
      this.props.updateOnscreen({ [immutableVariableName]: signaturePadElement.toDataURL() });
    }
  }

  renderWarnings = () => {
    const { thisFieldWarning } = this.props;

    if (thisFieldWarning) {
      return (
        <FieldValidationWarning failedValidations={thisFieldWarning} fieldId={this.props.id} />
      );
    }

    return null;
  }

  renderInput = () => {
    return (
      <div className="signature-input" id="signature-input">
        <canvas ref={this.canvasRef} />
      </div>
    );
  }

  renderLabel = () => {
    return (
      <React.Fragment>
        <MarkdownRenderer source={this.props.field.get('label')} />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-block__field-label">
          {this.renderLabel()}
        </div>
        <div className="page-block__field-input page-block__field-input--signature">
          {this.renderInput()}
          {this.renderWarnings()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  const warnings = getWarnings(state);
  const thisFieldWarning = warnings.get(ownProps.id);

  return {
    field,
    thisFieldWarning,
  };
};

const mapDispatchToProps = dispatch => ({
  updateOnscreen: data => dispatch(updateOnscreen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureField);
