import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getChoice,
  getField,
  getOnscreenValueForVariable,
} from '../../selectors';
import MarkdownRenderer from '../MarkdownRenderer';
import { SPACEBAR } from '../../utils';

class RadioChoice extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    choice: PropTypes.object.isRequired,
    parentOnscreenValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    parentOnscreenValue: null,
    disabled: false,
  }

  handleChange = () => {
    const value = this.props.choice.get('value');
    this.props.onChange(value);
  }

  listenForSelect = (e) => {
    if (e.keyCode === SPACEBAR) {
      e.preventDefault();
      this.handleChange();
    }
  }

  shouldBeChecked = () => {
    return this.props.parentOnscreenValue === this.props.choice.get('value');
  }

  radioClassName = () => {
    const isChecked = this.shouldBeChecked();
    if (isChecked) {
      return 'radio-input__input checked';
    }
    return 'radio-input__input';
  }

  renderInput = () => {
    return (
      <li className={this.radioClassName()} onKeyDown={this.props.listenForSubmit}>
        <label tabIndex="0" htmlFor={`choice-${this.props.id}`} onKeyDown={this.listenForSelect}>
          <input disabled={this.props.disabled} id={`choice-${this.props.id}`} aria-hidden="true" type="radio" checked={this.shouldBeChecked()} onClick={this.handleChange} readOnly />
          <i className="far fa-circle" />
          <i className="fas fa-check" />
          <MarkdownRenderer source={this.props.choice.get('label')} />
        </label>
      </li>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderInput()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const choice = getChoice(state, ownProps.id);
  const field = getField(state, choice.get('fieldId'));
  const parentVariableName = field.get('immutableVariableName');
  const parentOnscreenValue = getOnscreenValueForVariable(state, parentVariableName);
  return {
    choice,
    parentOnscreenValue,
  };
};

export default connect(mapStateToProps)(RadioChoice);
