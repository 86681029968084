import { fromJS } from 'immutable';
import {
  STOP_WORKING,
  START_WORKING,
  UPDATE_SAVE_AND_CONTINUE_MODAL_SHOWING,
  UPDATE_UI,
  APPEND_TO_FAST_FORWARD_PROGRESS,
} from '../actions/ui';
import { emptyMap, emptyList } from '../utils/emptiness';

const updateUi = (state, action) => {
  return state.merge(fromJS(action.data));
};

const startWorking = (state) => {
  return state.merge(fromJS({ working: true }));
};

const stopWorking = (state) => {
  return state.merge(fromJS({ working: false }));
};

const updateSaveAndContinueModalShowing = (state, action) => {
  return state.merge(fromJS({ saveAndContinueModalShowing: action.data }));
};

const appendToFastForwardProgress = (state, action) => {
  const oldFastForwardProgress = state.get('fastForwardProgress') || emptyList;
  const newFastForwardProgress = [...oldFastForwardProgress, action.data];
  return state.set('fastForwardProgress', newFastForwardProgress);
};

const uiReducer = (state, action) => {
  switch (action.type) {
    case START_WORKING:
      return startWorking(state);
    case STOP_WORKING:
      return stopWorking(state);
    case UPDATE_SAVE_AND_CONTINUE_MODAL_SHOWING:
      return updateSaveAndContinueModalShowing(state, action);
    case UPDATE_UI:
      return updateUi(state, action);
    case APPEND_TO_FAST_FORWARD_PROGRESS:
      return appendToFastForwardProgress(state, action);
    default:
      return state || emptyMap;
  }
};

export default uiReducer;
