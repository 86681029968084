import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getField,
  makeGetTextFor,
} from '../../selectors';
import { SPACEBAR } from '../../utils';

class YesNoRadio extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    value: PropTypes.bool,
    getTextFor: PropTypes.func.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    value: null,
  }

  listenForSelectTrue = (e) => {
    if (e.keyCode === SPACEBAR) {
      e.preventDefault();
      this.updateValueToTrue();
    }
  }

  listenForSelectFalse = (e) => {
    if (e.keyCode === SPACEBAR) {
      e.preventDefault();
      this.updateValueToFalse();
    }
  }

  updateValueToTrue = (e) => {
    this.props.onChange(true);
  }

  updateValueToFalse = (e) => {
    this.props.onChange(false);
  }

  clearSelection = () => {
    this.props.onChange(null);
  }

  shouldCheckTrue = () => {
    return (this.props.value === true);
  }

  shouldCheckFalse = () => {
    return (this.props.value === false);
  }

  yesClassName = () => {
    const isChecked = this.shouldCheckTrue();
    if (isChecked) {
      return 'boolean-input__input checked';
    }
    return 'boolean-input__input';
  }

  noClassName = () => {
    const isChecked = this.shouldCheckFalse();
    if (isChecked) {
      return 'boolean-input__input checked';
    }
    return 'boolean-input__input';
  }

  renderInput = () => {
    return (
      <fieldset className="boolean-input" onKeyDown={this.props.listenForSubmit}>
        <legend className="sr-only">Choices:</legend>
        <ul role="radiogroup">
          <li className={this.yesClassName()}>
            <label tabIndex="0" htmlFor={`field-yes-${this.props.id}`} aria-label="yes" onKeyDown={this.listenForSelectTrue}>
              <input id={`field-yes-${this.props.id}`} aria-hidden="true" type="radio" name={`yesnoradio-${this.props.id}`} defaultChecked={this.shouldCheckTrue()} value="true" onClick={this.updateValueToTrue} disabled={this.props.prerenderMode} />
              <i className="far fa-circle" />
              <i className="fas fa-check" />
              {this.props.getTextFor('yes')}
            </label>
          </li>
          <li className={this.noClassName()}>
            <label tabIndex="0" htmlFor={`field-no-${this.props.id}`} aria-label="no" onKeyDown={this.listenForSelectFalse}>
              <input id={`field-no-${this.props.id}`} aria-hidden="true" type="radio" name={`yesnoradio-${this.props.id}`} defaultChecked={this.shouldCheckFalse()} value="false" onClick={this.updateValueToFalse} disabled={this.props.prerenderMode} />
              <i className="far fa-circle" />
              <i className="fas fa-check" />
              {this.props.getTextFor('no')}
            </label>
          </li>
        </ul>
      </fieldset>
    );
  }

  renderClearButton = () => {
    if (this.props.required) return null;

    return (
      <div className="page-block__field-input__clear">
        <button className="btn-secondary" onClick={this.clearSelection}>
          <i className="fa fa-times" />
          Clear
        </button>
      </div>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <span className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInput()}
            {this.props.renderWarnings()}
          </div>
        </span>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.renderClearButton()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(YesNoRadio);
