import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getField } from '../../selectors';

class ImageElement extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    field: PropTypes.object.isRequired,
  }

  renderImage = () => {
    const { field } = this.props;
    const source = field.get('image');

    const rawWidth = field.get('width') || '100';
    const percentWidth = `${rawWidth}%`;

    const imageFilename = field.get('imageFilename');

    return (
      <img
        src={source}
        width={percentWidth}
        alt={imageFilename}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderImage()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  return {
    field,
  };
};

export default connect(mapStateToProps)(ImageElement);
