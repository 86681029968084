import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalWrapper from './ModalWrapper';
import copyToClipboard from '../utils/copy_to_clipboard';
import {
  getSaveAndContinueModalShowing,
  getSessionId,
  getSession,
  getSessionKey,
  makeGetTextFor,
  getClientPortalEndpoint,
} from '../selectors';
import { saveAndContinueRequest } from '../api';
import { updateSaveAndContinueModalShowing } from '../actions/ui';
import { isValidEmail } from '../utils';

class SaveAndContinueButton extends React.Component {
  static propTypes = {
    saveAndContinueModalShowing: PropTypes.bool.isRequired,
    updateSaveAndContinueModalShowing: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    session: PropTypes.object.isRequired,
    sessionKey: PropTypes.string.isRequired,
    getTextFor: PropTypes.func.isRequired,
    clientPortalEndpoint: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      emailAddress: '',
      emailSent: false,
      emailLastSentTo: '',
      invalidEmail: false,
      linkCopied: false,
      linkCopiedFading: false,
      clientPortalEndpoint: null,
    };
  }

  updateEmailAddress = (e) => this.setState({ emailAddress: e.target.value });

  showSaveAndContinueModal = () => this.props.updateSaveAndContinueModalShowing(true);

  hideSaveAndContinueModal = () => {
    this.props.updateSaveAndContinueModalShowing(false);
    this.setState({ emailAddress: '', invalidEmail: false });
  }

  renderInvalidEmailWarning = () => {
    if (!this.state.invalidEmail) return null;

    return (
      <span className="save-continue-modal__body--row__item">
        {this.props.getTextFor('validations.invalid-email')}
      </span>
    );
  }

  onEmailSendSuccess = () => {
    this.setState((currState) => ({
      emailSent: true,
      emailAddress: '',
      emailLastSentTo: currState.emailAddress,
      invalidEmail: false,
    }), () => {
      window.setTimeout(() => this.setState({ emailSent: false, emailLastSentTo: '' }), 5000);
    });
  }

  sendEmail = () => {
    if (isValidEmail(this.state.emailAddress)) {
      const params = {
        email: this.state.emailAddress,
        session_id: this.props.sessionId,
        interview_url: this.saveAndContinueUrl(),
      };
      this.setState({ invalidEmail: false });

      saveAndContinueRequest(params, this.onEmailSendSuccess);
    } else {
      this.setState({ invalidEmail: true });
    }
  }

  copyUrlToClipboard = () => {
    copyToClipboard(this.saveAndContinueUrl());

    // Initially sets linkCopied to true. After 3 seconds sets linkCopiedFading to true, and after 1 second
    // sets both to false.
    this.setState({ linkCopied: true }, () => {
      window.setTimeout(() => {
        this.setState({ linkCopiedFading: true }, () => {
          window.setTimeout(() => this.setState({ linkCopied: false, linkCopiedFading: false }), 1000);
        });
      }, 3000);
    });
  }

  saveAndContinueUrl = () => {
    return this.props.session.get('save_and_continue_link');
  }

  renderModal = () => {
    if (!this.props.saveAndContinueModalShowing) return null;

    const emailSuccessfullySentElement = (
      <div className="save-continue-modal__success-message">
        <span role="img" aria-label="success">✅</span> {this.props.getTextFor('save-and-continue.email-sent')} {this.state.emailLastSentTo}.
      </div>
    );

    const linkCopiedElement = (
      <span className={`save-continue-modal__link-copied${this.state.linkCopiedFading ? ' save-continue-modal__link-copied--fading animate__animated animate__fadeOut' : ''}`}>
        <i className="fas fa-clipboard-check" />
        {this.props.getTextFor('save-and-continue.link-copied')}
      </span>
    );

    return (
      <ModalWrapper isOpen onRequestClose={this.hideSaveAndContinueModal}>
        <div className="save-continue-modal">
          <div className="save-continue-modal__header">
            <div>
              <h3>
                {this.props.getTextFor('save-and-continue.continue-later')}
              </h3>
              <p>
                {this.props.getTextFor('save-and-continue.description')}
              </p>
            </div>
            <div className="save-continue-modal__btn-exit" onClick={this.hideSaveAndContinueModal}>
              <i className="fa fa-times" />
            </div>
          </div>
          <div className="save-continue-modal__body">
            <div className="save-continue-modal__body--row">
              <label htmlFor="email-field">
                {this.props.getTextFor('save-and-continue.email-address')}
              </label>
              <input id="email-field" className="short-text-input" onChange={this.updateEmailAddress} value={this.state.emailAddress} />
              <div>
                <button type="submit" className="btn-primary" onClick={this.sendEmail}>{this.props.getTextFor('save-and-continue.send-link')}</button>
                {this.renderInvalidEmailWarning()}
              </div>
            </div>
            <p>
              {this.props.getTextFor('save-and-continue.copy-link-beginning')} <span className="save-continue-modal__download-link" onClick={this.copyUrlToClipboard}>{this.props.getTextFor('save-and-continue.copy-link-link-portion')}</span>.
            </p>
            {this.state.linkCopied ? linkCopiedElement : null}
          </div>
          {this.state.emailSent ? emailSuccessfullySentElement : null}
          <div className="save-continue-modal__footer">
            <i className="fas fa-lock" />
            <p className="save-continue-modal__disclaimer">
              {this.props.getTextFor('save-and-continue.warning')}
            </p>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  saveAndContinueModalButton = () => {
    return (
      <button className="save-continue-button" id="save-continue" onClick={this.showSaveAndContinueModal}>
        <label htmlFor="save-continue">
          {this.props.getTextFor('save-and-continue.continue-later')}
        </label>
      </button>
    );
  }

  saveAndContinueLinkButton = () => {
    return (
      <a className="save-continue-button" tabIndex="0" href={this.props.clientPortalEndpoint} target="_blank" rel="noopener noreferrer">
        {this.props.getTextFor('save-and-continue.continue-later')}
      </a>
    );
  }

  renderSaveAndContinueButton = () => {
    if (this.props.clientPortalEndpoint) {
      return this.saveAndContinueLinkButton();
    }

    return this.saveAndContinueModalButton();
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModal()}
        {this.renderSaveAndContinueButton()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    saveAndContinueModalShowing: getSaveAndContinueModalShowing(state),
    sessionId: getSessionId(state),
    session: getSession(state),
    sessionKey: getSessionKey(state),
    getTextFor: makeGetTextFor(state),
    clientPortalEndpoint: getClientPortalEndpoint(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSaveAndContinueModalShowing: (data) => dispatch(updateSaveAndContinueModalShowing(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveAndContinueButton);
