import React from 'react';
import PropTypes from 'prop-types';

class Redirect extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }

  componentDidMount = () => {
    if (window.sessionStorage) {
      window.sessionStorage.removeItem('app_session_id');
    }
    window.location.href = this.props.url;
  }

  render() {
    return (
      <React.Fragment>
        {this.props.text}
      </React.Fragment>
    );
  }
}

export default Redirect;
