import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateSessionRequest } from '../../api';
import {
  getSession,
  getIsWorking,
  makeGetTextFor,
} from '../../selectors';
import { loadNewState } from '../../actions';
import {
  startWorking,
  stopWorking,
} from '../../actions/ui';

class LoopMember extends React.Component {
  static propTypes = {
    member: PropTypes.string,
    session: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    loadNewState: PropTypes.func.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    working: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: '',
  }

  stopWorking = () => {
    this.setState({ working: false });
  }

  startWorking = () => {
    this.setState({ working: true });
  }

  handleDelete = () => {
    this.props.startWorking();

    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };

    const failure = (data) => {
      this.props.stopWorking();
      alert(data.message);
    };

    const id = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const updateType = 'delete_element';
    updateSessionRequest(id, sessionKey, updateType, null, null, this.props.index, success, failure);
  }

  handleEdit = () => {
    this.props.startWorking();

    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };

    const failure = (data) => {
      this.props.stopWorking();
      alert(data.message);
    };

    const id = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const updateType = 'edit_element';
    updateSessionRequest(id, sessionKey, updateType, null, null, this.props.index, success, failure);
  }

  renderDeleteButton = () => {
    return (
      <button className="loop-members__delete-button" disabled={this.props.working} onClick={this.handleDelete}>
        <i className="far fa-trash-alt" />
        {this.props.getTextFor('loops.delete')}
      </button>
    );
  }

  renderEditButton = () => {
    return (
      <button className="loop-members__edit-button" disabled={this.props.working} onClick={this.handleEdit}>
        <i className="far fa-edit" />
        {this.props.getTextFor('loops.edit')}
      </button>
    );
  }

  render() {
    const memberDisplayIndex = this.props.index + 1;
    const memberDisplayValue = (this.props.member && this.props.member.length > 0) ? ` - ${this.props.member}` : '';
    const fullMemberDisplay = `${memberDisplayIndex}${memberDisplayValue}`;

    return (
      <div className="loop-members__member">
        <div className="loop-members__member--label-col">
          {fullMemberDisplay}
        </div>
        <div className="loop-members__member--button-col">
          {this.renderEditButton()}
          {this.renderDeleteButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: getSession(state),
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => { dispatch(loadNewState(data)); },
  stopWorking: (data) => dispatch(stopWorking(data)),
  startWorking: (data) => dispatch(startWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoopMember);
