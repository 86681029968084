import { fromJS } from 'immutable';
import { UPDATE_ONSCREEN } from '../actions/onscreen';
import { emptyMap } from '../utils/emptiness';

const updateOnscreen = (state, action) => {
  return state.merge(fromJS(action.data));
};

const onscreenReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ONSCREEN:
      return updateOnscreen(state, action);
    default:
      return state || emptyMap;
  }
};

export default onscreenReducer;
