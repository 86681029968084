import { runtimeValue } from './runtime_value';
import { getOperatorFor } from './operators';
import { getNullDisposition } from '../selectors';

// this is adapted from app/lib/expression_evaluator.rb

export const evaluateExpressions = (state, expressions) => {

  const nullDisposition = getNullDisposition(state);

  const tuples = expressions.map((expression) => {
    const leftHandReference = expression.get('leftHandValue');
    const rightHandReference = expression.get('rightHandValue');
    const operatorIdentifier = expression.get('operatorIdentifier');
    const linkingOperatorIdentifier = expression.get('linkingOperatorIdentifier');

    const leftHandValue = runtimeValue(state, leftHandReference);

    if (operatorIdentifier) {
      const operator = getOperatorFor(operatorIdentifier, nullDisposition);
      const rightHandValue = (operator.arity === 2) ? runtimeValue(state, rightHandReference) : null;
      return { value: operator.operate(leftHandValue, rightHandValue), linkingOperator: linkingOperatorIdentifier };
    } else {
      return { value: leftHandValue, linkingOperator: linkingOperatorIdentifier }
    }
  });

  const firstTuple = tuples.first();
  const trailingTuples = tuples.rest();

  return trailingTuples.reduce((buildup, newTuple) => {
    const operator = getOperatorFor(newTuple.linkingOperator, nullDisposition);
    return operator.operate(buildup, newTuple.value);
  }, firstTuple.value);
};
