import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadSessionRequest, newSessionRequest } from '../api';
import { loadNewState } from '../actions';
import { appPath } from '../utils';

class AppLandingPage extends React.Component {
  static propTypes = {
    sessionId: PropTypes.string,
    _accessKey: PropTypes.string,
    appId: PropTypes.string,
    urlParams: PropTypes.string,
    loadNewState: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sessionId: null,
    _accessKey: null,
    urlParams: '',
    appId: null,
  }

  componentDidMount = () => {
    const success = (object) => {
      this.props.loadNewState(object);
      if (window.sessionStorage) {
        window.sessionStorage.setItem('app_session_id', object.id);
        window.sessionStorage.setItem('app_path', appPath());
      }
    };
    const failure = (message) => {
      alert(message);
    };

    const {
      sessionId,
      appId,
      _accessKey,
      urlParams,
    } = this.props;

    let sessionStorageId;
    if (window.sessionStorage) {
      sessionStorageId = window.sessionStorage.getItem('app_session_id');
    } else {
      sessionStorageId = null;
    }

    if (sessionId) {
      loadSessionRequest(sessionId, success, failure);
    } else if (sessionStorageId) {
      loadSessionRequest(sessionStorageId, success, failure);
    } else {
      const parsedParams = JSON.parse(urlParams);
      newSessionRequest(appId, _accessKey, parsedParams, success, failure);
    }
  }

  renderSpinner = () => {
    // do not render spinner if we're prerendered:
    if (document.querySelector('#rendered')) {
      return <span />;
    }
    return (
      <span>
        <i className="fas fa-spinner fa-spin" />
      </span>
    );
  }

  render() {
    return (
      <div className="loading-screen">
        {this.renderSpinner()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
});

export default connect(null, mapDispatchToProps)(AppLandingPage);
