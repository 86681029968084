import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getField } from '../../selectors';

class RangeField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    field: PropTypes.object.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    value: null,
  }

  handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    this.props.onChange(value);
  }

  getValue = () => {
    let { value } = this.props;
    if (value == null || value === undefined) value = '';

    return value;
  }

  renderInput = () => {
    const { id, field } = this.props;
    const value = this.getValue();

    const existingValueElement = value !== '' ? (
      <span className="range-input__value">
        {value}
      </span>
    ) : null;

    return (
      <div className="range-input">
        <div className="range-input__top-row">
          <div className="range-input__top-row--col">
            {field.get('min')}
          </div>
          <input
            type="range"
            id={`field-${id}`}
            onChange={this.handleChange}
            value={value}
            min={field.get('min')}
            max={field.get('max')}
            step={field.get('step')}
            className="range-input__slider"
            onKeyDown={this.props.listenForSubmit}
            disabled={this.props.prerenderMode}
          />
          <div className="range-input__top-row--col">
            {field.get('max')}
          </div>
        </div>
        <div className="range-input__bottom-row">
          {existingValueElement}
        </div>
      </div>
    );
  }

  renderInlineInput = () => {
    const { id, field } = this.props;
    const value = this.getValue();

    const existingValueElement = value ? (
      <span className="range-input__value">
        {value}
      </span>
    ) : null;

    return (
      <span className="range-input">
        <span className="range-input__top-row">
          <span className="range-input__top-row--col">
            {field.get('min')}
          </span>
          <input
            type="range"
            id={`field-${id}`}
            onChange={this.handleChange}
            value={value}
            min={field.get('min')}
            max={field.get('max')}
            step={field.get('step')}
            className="range-input__slider"
            onKeyDown={this.props.listenForSubmit}
            disabled={this.props.prerenderMode}
          />
          <span className="range-input__top-row--col">
            {field.get('max')}
          </span>
        </span>
        <span className="range-input__bottom-row">
          {existingValueElement}
        </span>
      </span>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInlineInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <label htmlFor={`field-${this.props.id}`} className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </label>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(RangeField);
