import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getField,
  getChoicesForField,
  makeGetTextFor,
} from '../../selectors';
import RadioChoice from '../choices/RadioChoice';

class Radio extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    field: PropTypes.object.isRequired,
    choices: PropTypes.object.isRequired,
    getTextFor: PropTypes.func.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
  }

  handleChange = (e) => {
    this.props.onChange(e);
  }

  clearSelection = () => {
    this.props.onChange(null);
  }

  renderInput = () => {
    const choices = this.props.choices.sortBy((choice) => { return choice.get('order'); }).map((choice) => {
      const choiceId = choice.get('id');

      return (
        <RadioChoice
          id={choiceId}
          onChange={this.handleChange}
          key={choiceId}
          listenForSubmit={this.props.listenForSubmit}
          disabled={this.props.prerenderMode}
        />
      );
    });
    return (
      <fieldset className="radio-input">
        <legend className="sr-only">{this.props.getTextFor('choices')}:</legend>
        <ul role="radiogroup">
          {choices}
        </ul>
      </fieldset>
    );
  }

  renderClearButton = () => {
    if (this.props.required) return null;

    return (
      <div className="page-block__field-input__clear">
        <button className="btn-secondary" onClick={this.clearSelection}>
          <i className="fa fa-times" />
          Clear
        </button>
      </div>
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <div className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </div>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.renderClearButton()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);
  const choices = getChoicesForField(state, ownProps.id);

  return {
    field,
    choices,
    getTextFor: makeGetTextFor(state),
  };
};

export default connect(mapStateToProps)(Radio);
