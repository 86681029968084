/* globals Stripe */

import React from 'react';
import { Provider } from 'react-redux';
import { ActionCableProvider } from 'react-actioncable-provider';
import { createStore } from 'redux';
import { Elements } from '@stripe/react-stripe-js';
import AppWrapper from './AppWrapper';
import reducer from '../reducers';
import normalizeAppSession from '../normalizers/app_session';

const mountNodeId = 'data-node';
const mountNode = document.getElementById(mountNodeId);
const stripePublicKey = mountNode.getAttribute('data-stripe_public_key');
const stripeConnectedAccount = mountNode.getAttribute('data-stripe_connected_account');
const urlParams = mountNode.getAttribute('data-url_params');

const shouldLoadStripe = mountNode.getAttribute('data-should_load_stripe') === 'true';
const stripe = shouldLoadStripe ? (new Stripe(stripePublicKey, { stripeAccount: stripeConnectedAccount })) : null;

const App = ({
  prerenderSessionJson,
}) => {
  let store;

  if (prerenderSessionJson) {
    const normalized = normalizeAppSession(null, prerenderSessionJson);
    store = createStore(
      reducer,
      normalized,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  } else {
    store = createStore(
      reducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  }

  const appId = mountNode.getAttribute('data-compiled_app_id');
  const accessKey = mountNode.getAttribute('data-access_key');

  const sessionId = mountNode.getAttribute('data-app_session_id');
  const sessionKey = mountNode.getAttribute('data-session_key');

  const socketUrl = mountNode.getAttribute('data-socket_url');

  return (
    <Provider store={store}>
      <ActionCableProvider url={socketUrl}>
        <Elements stripe={stripe}>
          <AppWrapper
            appId={appId}
            _accessKey={accessKey}
            sessionId={sessionId}
            sessionKey={sessionKey}
            urlParams={urlParams}
          />
        </Elements>
      </ActionCableProvider>
    </Provider>
  );
};

export default App;
