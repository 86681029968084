import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getDeveloperMode,
  getSession,
  getSnapshotStatusMessage,
  getIsMakingSnapshot,
  getIsWorking,
} from '../selectors';
import {
  createSnapshotRequest,
  fastForwardRequest,
} from '../api';
import { updateUi, startWorking } from '../actions/ui';
import { loadNewState } from '../actions';
import CrystalBall from './CrystalBall';

class DeveloperModeBadge extends React.Component {
  static propTypes = {
    developerMode: PropTypes.bool.isRequired,
    session: PropTypes.object.isRequired,
    updateUi: PropTypes.func.isRequired,
    isMakingSnapshot: PropTypes.bool.isRequired,
    snapshotStatusMessage: PropTypes.string.isRequired,
    isWorking: PropTypes.bool.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    loadNewState: PropTypes.func.isRequired,
  }

  saveSnapshot = () => {
    this.props.updateUi({
      isMakingSnapshot: true,
      snapshotStatusMessage: '',
    });

    const sessionId = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const success = (object) => {
      // no-op
    };
    const failure = (message) => {
      alert(message);
    };
    createSnapshotRequest(sessionId, sessionKey, success, failure);
  }

  fastForward = () => {
    this.props.startWorking();
    const sessionId = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };
    const failure = (message) => {
      alert(message);
    };
    fastForwardRequest(sessionId, sessionKey, success, failure);
  }

  renderSnapshotControls = () => {
    const { isMakingSnapshot, snapshotStatusMessage } = this.props;
    return (
      <React.Fragment>
        <button className="test-mode-badge__btn" onClick={this.saveSnapshot} disabled={isMakingSnapshot}>
          <i className="fa fa-save" />
          {isMakingSnapshot ? 'Working...' : 'Save snapshot'}
        </button>
        <div className="test-mode-badge__message">
          {snapshotStatusMessage}
        </div>
      </React.Fragment>
    );
  }

  renderFastForward = () => {
    const { isWorking } = this.props;
    return (
      <React.Fragment>
        <p className="test-mode-badge__sub-title">
          <button className="test-mode-badge__btn" onClick={this.fastForward} disabled={isWorking}>
            <i className="fa fa-angle-double-right" />
            Fast forward
          </button>
        </p>
      </React.Fragment>
    );
  }

  renderCrystallBall = () => {
    return (
      <React.Fragment>
        <CrystalBall />
      </React.Fragment>
    );
  }

  render() {
    if (this.props.developerMode) {
      return (
        <React.Fragment>
          <div className="test-mode-badge">
            <div className="test-mode-badge__header">
              <p className="test-mode-badge__title">
                Developer mode
              </p>
              <a target="_blank" href="https://university.afterpattern.com/lesson-list/activate-and-share-apps#snapshots" className="test-mode-badge__question">
                <i className="fas fa-question-circle"></i>
              </a>
            </div>
            {this.renderSnapshotControls()}
            {this.renderFastForward()}
            <div className="test-mode-badge__cball-wrapper">
              {this.renderCrystallBall()}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  const developerMode = getDeveloperMode(state);

  return {
    developerMode,
    session: getSession(state),
    snapshotStatusMessage: getSnapshotStatusMessage(state),
    isMakingSnapshot: getIsMakingSnapshot(state),
    isWorking: getIsWorking(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUi: (data) => dispatch(updateUi(data)),
  startWorking: (data) => dispatch(startWorking(data)),
  stopWorking: (data) => dispatch(startWorking(data)),
  loadNewState: (data) => dispatch(loadNewState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperModeBadge);
