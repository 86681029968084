import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getField } from '../../selectors';

class TimeField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    renderLabel: PropTypes.func.isRequired,
    renderRequired: PropTypes.func.isRequired,
    renderWarnings: PropTypes.func.isRequired,
    listenForSubmit: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    inline: PropTypes.bool.isRequired,
    prerenderMode: PropTypes.bool.isRequired,
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  }

  renderInput = () => {
    return (
      <input
        type="time"
        id={`field-${this.props.id}`}
        onChange={this.handleChange}
        onKeyDown={this.props.listenForSubmit}
        value={this.props.value}
        className="time-input"
        disabled={this.props.prerenderMode}
      />
    );
  }

  render() {
    if (this.props.inline) {
      return (
        <div className="inline-wrapper__field-wrapper">
          <div className="inline-wrapper__field-wrapper--field">
            {this.props.renderRequired()}
            {this.renderInput()}
            {this.props.renderWarnings()}
          </div>
        </div>
      );
    }

    return (
      <div className="page-block__field-wrapper">
        <label htmlFor={`field-${this.props.id}`} className="page-block__field-label">
          {this.props.renderLabel()}
          {this.props.renderRequired()}
        </label>
        <div className="page-block__field-input">
          {this.renderInput()}
          {this.props.renderWarnings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const field = getField(state, ownProps.id);

  return {
    field,
  };
};

export default connect(mapStateToProps)(TimeField);
