import { runtimeValue } from './runtime_value';
import { getNullDisposition } from '../selectors';

// this is adapted from app/lib/condition_evaluator.rb

export const evaluateConditions = (state, conditions, fallback) => {

  const nullDisposition = getNullDisposition(state);

  if (nullDisposition === 'strong') {
    return strongNullEvaluation(state, conditions, fallback);
  }

  return weakNullEvaluation(state, conditions, fallback);
};

const strongNullEvaluation = (state, conditions, fallback) => {
  const relevantPlank = conditions.find((plank) => {
    const condition = plank.get('condition');
    const conditionRuntimeValue = runtimeValue(state, condition);
    return (conditionRuntimeValue === true || conditionRuntimeValue === null);
  });
  if (relevantPlank) {
    const myPlankCondition = relevantPlank.get('condition');
    const myPlankConditionRuntimeValue = runtimeValue(state, myPlankCondition);
    if (myPlankConditionRuntimeValue === null) {
      return null;
    }
    if (myPlankConditionRuntimeValue === true) {
      return runtimeValue(state, relevantPlank.get('value'));
    }
  }
  return runtimeValue(state, fallback);
};

const weakNullEvaluation = (state, conditions, fallback) => {
  const firstTruePlank = conditions.find((plank) => {
    const condition = plank.get('condition');
    return runtimeValue(state, condition);
  });
  if (firstTruePlank) {
    return runtimeValue(state, firstTruePlank.get('value'));
  }
  return runtimeValue(state, fallback);
};
