import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSession,
  getBlockId,
  getSubmissibleOnscreenVariables,
  getWarnings,
  getIsWorking,
  makeGetTextFor,
} from '../../selectors';
import { updateWarnings } from '../../actions/warnings';
import { startWorking, stopWorking } from '../../actions/ui';
import { updateSessionRequest } from '../../api';
import { loadNewState } from '../../actions';

class Submit extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
    blockId: PropTypes.string.isRequired,
    loadNewState: PropTypes.func.isRequired,
    submissibleOnscreenVariables: PropTypes.object.isRequired,
    updateWarnings: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    warnings: PropTypes.object.isRequired,
    startWorking: PropTypes.func.isRequired,
    stopWorking: PropTypes.func.isRequired,
    working: PropTypes.bool.isRequired,
    getTextFor: PropTypes.func.isRequired,
  }

  handleSubmit = () => {

    this.props.startWorking();

    const success = (object) => {
      this.props.stopWorking();
      this.props.loadNewState(object);
    };
    const failure = (data) => {
      if (data.errors) {
        this.props.updateWarnings(data.errors);
      } else {
        alert(data.message);
      }
      this.props.stopWorking();
    };

    const variables = this.props.submissibleOnscreenVariables.toJS();
    const id = this.props.session.get('id');
    const sessionKey = this.props.session.get('metadata').get('session_key');
    const blockId = this.props.blockId;
    const updateType = 'submission';

    updateSessionRequest(id, sessionKey, updateType, variables, blockId, null, success, failure);
  }

  renderRequirednessWarning = () => {
    if (this.props.warnings.size > 0) {
      return (
        <div className="page-block__submit--warning animate__animated">
          {this.props.getTextFor('validations.required-warning')}
        </div>
      );
    }

    return null;
  }

  submitButtonCssClass = () => {
    if (this.props.working) {
      return 'btn-primary disabled';
    }
    return 'btn-primary';
  }

  render() {
    return (
      <div className="page-block__submit page-block__submit--signature">
        {this.renderRequirednessWarning()}
        <div className="page-block__submit--button-row">
          <button className="btn-secondary" onClick={this.props.onClear} disabled={this.props.working}>
            {this.props.getTextFor('clear')}
          </button>
          <button className={this.submitButtonCssClass()} onClick={this.handleSubmit} disabled={this.props.working}>
            <i className="fas fa-spinner fa-spin" />
            {this.props.getTextFor('continue')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const blockId = getBlockId(state);

  return {
    blockId,
    session: getSession(state),
    submissibleOnscreenVariables: getSubmissibleOnscreenVariables(state),
    warnings: getWarnings(state),
    working: getIsWorking(state),
    getTextFor: makeGetTextFor(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNewState: (data) => dispatch(loadNewState(data)),
  updateWarnings: (data) => dispatch(updateWarnings(data)),
  stopWorking: (data) => dispatch(stopWorking(data)),
  startWorking: (data) => dispatch(startWorking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
